.bank-account-modal-button button {
  @media (min-width: 993px) {
    text-align: right !important;
    display: block !important;
  }
}

.bank-account-modal-form-actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 1em;
}

.toggle-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
}

.toggle-text-wrapper {
  padding: 0;
  max-width: 100%;
}
.toggle-option {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap:1rem;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-self: right;
  & label {
    justify-self: right;
    margin: 0;
  }
  @media (min-width: 993px) {
    grid-template-columns: 1fr 15%;
  }
}
.react-toggle {
  justify-self: right;
}
.header-cashout {
  margin: 0;
}
.bank-account-detail-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;

  @media (min-width: 993px) {
    grid-template-columns: 4fr 1fr;
  }
}
.bank-account-row-detail{
  display: grid;
  grid-row-gap: 5px;
}
.bank-detail-types {
    display: grid;
    grid-row-gap: 5px;
    @media (min-width: 993px) {
      grid-template-columns: 170px 1fr;
    }
}
.bank-detail-item-type {
  width: fit-content;
  max-width: 100%;
}
.bank-account-modal-form-actions button {
  display: flex;
  justify-content: flex-end;

  @media (min-width: 993px) {
    text-align: right !important;
    display: block !important;
    width: auto !important;
  }
}
