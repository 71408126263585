.payment-link-preview {
  background: #f8f8ff;
  border-radius: 2px;
  line-height: $font-size-h5;
}

.payment-link-preview__description,
.payment-link-preview__url {
  font-size: $font-size-sm;
  color: $color-gray-600;
}

.payment-link-preview__name {
  font-size: $font-size-h5;
  color: $color-gray-900;
}

.payment-link-preview__price {
  font-size: $font-size-h5;
  margin-bottom: 0;
}
