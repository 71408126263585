.payment-link-form {
  margin-top: 20px;

  label {
    color: $color-gray-900;
    font-weight: $font-weight-bold;
    font-size: 13px;
  }

  .form-control {
    height: 40px;
  }

  .form-group {
    padding: 15px;
    margin-bottom: 0;
  }
}
