@import '../variables.scss';
@import "../bootstrap/variables";
@import "../globals.scss";
@import "../colors.scss";

.login-container {
    .form-control:focus {
        border-color: $dashboard-primary-color;
        color:$dashboard-primary-color;
    }
    
    .input-color-error {
        border-color:$red;
    }
}
