@import "variables";

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  min-height: 100%;
  font-size: 14px;
  font-family: Roboto, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a,
button {
  cursor: pointer;
}

svg {
  display: block;
}

hr {
  border-top: 1px solid $color-gray-200;
}

pre {
  white-space: pre-wrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.strong {
  &--500 {
    font-weight: 500;
  }
  &--700 {
    font-weight: 700;
  }
}

.full-height {
  height: 100%;
}

.debug-code {
  position: fixed;
  top: 10px;
  left: 10px;
  min-width: 500px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.49);
  border-radius: 5px;
}

.page {
  min-height: 100%;
  height: 100%;
  margin: 0 auto -75px;
}

.text-light a {
  color: $text-light;
}

.text-normal a {
  color: $text-normal;
}

.btn {
  border-radius: 0;
  padding: 12px 24px;
  i.mdi {
    vertical-align: bottom !important;
  }
  i.mdi-middle {
    vertical-align: middle !important;
  }
}
.btn-lg {
  height: $input-height-lg;
}
.btn-sm {
  height: $input-height-sm;
}

.btn.btn-text,
.btn.btn-text:hover,
.btn.btn-text:focus {
  padding: 1px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.btn-link {
  &:focus {
    color: $tenant-primary-color;
    text-decoration: none;
    box-shadow: none;
  }
}

.dropdown-menu {
  padding: 0;

  .nav-menu-item,
  .dropdown-item.nav-menu-item {
    color: $color-gray-400;
    padding: 16px 26px;

    i:before,
    span {
      vertical-align: middle;
    }

    i {
      margin-right: 8px;
    }
  }

  .dropdown-item {
    padding: 10px 16px;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $white;
      background-color: $dashboard-secondary-color;
    }

    &:active {
      color: $white;
      background-color: $dashboard-primary-color;
    }
  }
}

.client--image,
.catalog__product--image {
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  margin-right: 17px;
  width: 70px;
  height: 70px;
  line-height: 68px;
  color: $white;
  background-color: $color-gray-400;
  border-radius: 50%;
  border: 1px solid $color-gray-100;
  text-align: center;
  text-transform: uppercase;

  img {
    margin-top: -2px;
    min-width: auto;
    height: 70px;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;

  .flex-content {
    flex: auto;
  }
}

.request-error {
  display: block;
  padding: 10px 0;
  color: $red;
  font-size: 12px;
}

.full-screen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-message {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $color-slate-600;
  font-size: 16px;

  &-image {
    height: 118px;
    min-width: 118px;
    width: auto;
    margin-bottom: 48px;
  }

  .message {
    margin-bottom: 32px;
  }

  .btn {
    margin: 18px;
    min-width: 180px;
  }

  h5 {
    font-weight: 400;
    color: $tenant-primary-color;
    margin-bottom: 24px;
  }
}

.display-none {
  display: none !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.btn-left {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.client-section input {
  height: 40px !important;
}
