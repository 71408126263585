
.stats-section {

  .stats-section-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media(max-width:576px) {
      flex-direction: column;
      align-items: center;
      flex: auto;

      .stats-filters-compare-switch {
        margin-top: -6px;
        margin-bottom: 4px;
      }
    }

    .stats-filters-compare-switch {
      height: 48px;
      margin-left: 4px;
      margin-right: 16px;
      display: flex;
      align-items: center;
    }
  }

  .stats-section-content {
    flex: auto;
    position: relative;

    .chart-col {
      margin-bottom: 28px;
    }

    .chart-message {
      margin-top: -8px;
    }
  }
}
