@import "../variables.scss";
@import "../bootstrap/variables";
@import "../globals.scss";
@import "../colors.scss";

.btn-loader {
  width: 107.16px;
  height: 46px;
  border-radius: none;
  border-style: none;
  float: right !important;
  background-color: $green;
  color: $white !important;
  stroke: $white !important;
  div {
    height: 25px;
    bottom: -10px;
  }
}

.link-loader {
  width: 107.16px;
  height: 46px;
  border: none !important;
  background-color: none !important;
  background: none !important;
  color: $tenant-primary-color !important;
  text-decoration: none !important;
  cursor: pointer;
}
