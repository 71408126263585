@import "variables";

.merchant-section {
  .reader-actions__menu {
    z-index: 3;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    .reader-actions__menu--item {
      padding: 18px 22px;
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: $gray-light;
      text-align: center;

      p {
        font-size: 12px;
        color: $gray;
      }

      &:last-of-type {
        margin: 0;
        border: 0;
      }
    }
  }
}

.btn {
  line-height: 20px;
}

.dashboard-panel-container 
  .form-control[type="number"], 
  .form-control[type="tel"], 
  .form-control[type="text"],
  .form-control[type="password"] {
  height: 40px !important;
}