.paginatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: auto;
  background-color: $white;
}

.paginationLink {
  width: 40px;
  height: 40px;
}

.paginationLinkActive {
  background-color: $tenant-primary-color;
  color: $white;
}

.pageSizeContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.pageSizeContainer .input-group .Select {
  min-width: 6em;
  margin: 0 5px 0 5px;
}

.pageSizeText {
  color: $color-gray-400;
}

.paginatorInfoContainer {
  margin: 0 0 0 10px;
  color: $color-gray-400;
}

@media (max-width: 720px) {
  .paginatorContainer {
    flex-direction: column;
  }
}
