@import "variables";
@import "tenantVariants";

.dashboard-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .btn {
    i {
      &:before {
        vertical-align: middle;
      }

      &.mdi-left {
        margin-right: 4px;
      }

      &.mdi-right {
        margin-left: 4px;
      }
    }
  }

  .dashboard-header-wrapper {
    height: 70px;

    .dashboard-header {
      position: fixed;
      top: 0;
      height: 70px;
      width: 100%;
      background-color: $tenant-background-color-primary;
      z-index: 99;

      .navbar {
        padding: 0 28px;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a:hover {
          text-decoration: none;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $color-gray-100;
          height: 1px;
          z-index: 1010;
        }

        .navbar-brand {
          padding: 0;
          margin: 8px 28px 8px 0;
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            height: 36px;
            width: auto;
          }
        }

        .navbar-toggler {
          border: 0;
          color: $color-gray-400;
          font-size: 32px;
          padding: 0 28px 0 0;
          height: 70px;

          i {
            vertical-align: middle;
            line-height: 70px;
          }

          &.active {
            color: $color-gray-900;
          }

          &:hover {
            cursor: pointer;
            color: $color-gray-900;
          }
        }

        .navbar-menu {
          flex: 1 1 auto;
          overflow: hidden;
          z-index: 1020;
        }

        .navbar-menu-right {
          font-size: 42px;
          margin: 0;
          padding: 0 0 0 24px;
          position: relative;

          .dropdown-menu {
            min-width: 328px;
            margin: 0;
            z-index: 10000;
          }

          .nav-link {
            padding: 0;
            height: 70px;
            color: $tenant-nav-link-color-default;

            i {
              vertical-align: middle;
            }

            .lblAccount {
              font-size: 14px;
              display: inline-block;
              padding-right: 10px;
              position: relative;
              vertical-align: middle;
            }

            .iconLinkLabelAccount {
              padding-left: 5px;
            }

            @media (max-width: 1070px) {
              .lblAccount {
                display: none;
              }

              .iconLinkLabelAccount {
                display: none;
              }
            }

            &.active,
            &:hover {
              color: $color-gray-400;
            }
          }
        }

        .navbar-nav {
          border-top: 1px solid $color-gray-100;

          .nav-link {
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid $color-gray-100;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            color: $tenant-nav-link-color-default;
            font-weight: 700;
            background-color: $tenant-background-color-primary;
            transition: color 0.2s;
            z-index: 16;

            i {
              font-size: 18px;
              line-height: 18px;
              margin-right: 6px;
              margin-bottom: 1px;
            }

            &:hover {
              text-decoration: none;
              color: $tenant-nav-link-hover-color;
            }

            &.active {
              background-color: $color-gray-50;
              color: $tenant-primary-color;
            }
          }
        }

        @media (max-width: 775px) {
          .navbar-menu {
            position: absolute;
            width: 100%;
            left: 0;
            top: 69px;
          }
        }

        @media (min-width: 776px) {
          .navbar-toggler {
            display: none;
          }

          .navbar-brand {
            display: none;
          }

          .collapse {
            display: block;
          }

          .navbar-nav {
            border-top: 0;
            flex-direction: row;

            .nav-item {
              flex: 1 1 auto;
            }

            .nav-link {
              height: 70px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 8px;
              border-bottom: 1px solid $color-gray-100;

              i {
                font-size: 20px;
                line-height: 32px;
                margin-right: 0;
              }

              &.active {
                padding: 0 7px 1px 7px;
                background-color: $color-gray-50;
                border-left: 1px solid $color-gray-100;
                border-right: 1px solid $color-gray-100;
                border-bottom: 0;
              }
            }
          }
        }

        @media (min-width: 1010px) {
          .navbar-brand {
            display: flex;
          }
        }

        @media (min-width: 1310px) {
          .navbar-nav {
            .nav-item {
              flex: 0 1 auto;
            }

            .nav-link {
              padding: 0 24px;
              flex-direction: row;

              i {
                font-size: 18px;
                line-height: 18px;
                margin-right: 6px;
              }

              &.active {
                padding: 0 23px 1px 23px;
              }
            }
          }
        }
      }
    }
  }

  .dashboard-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    overflow: hidden;
    background-color: $color-gray-50;
  }
}

.dashboard-header__account-name {
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
}

.card-body {
  padding: 20px;
  min-height: auto;
}
.card-lg {
  .card-body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    .card-title {
      border-bottom: none !important;
    }
  }
}

.text-emphasize {
  color: $tenant-primary-color;
}

.card {
  border: 0 !important;
  .card {
    margin: 0 !important;
  }
}

.card-empty {
  text-align: center;
  font-size: 20px;
  color: $color-gray-400;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  height: 100%;
  i {
    font-size: 32px;
  }
}
.dashboard-debug {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  width: 500px;
  height: 100vh;
  font-size: 12px;
  background-color: $white;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.modal-dialog.import-modal {
  .modal-body {
    padding-bottom: 0;
  }
  .close {
    padding: 13px 30px 0 0;
  }
}

.one-line-warning {
  display: flex;
  align-items: center;
  background-color: $color-warning-50;
  border: 1px solid $color-warning-900;
  p {
    color: $color-warning-900;
    font-size: 12px;
    margin: 0;
  }
  i {
    color: $color-warning-900;
    font-size: 20px;
    margin: 0 5px;
  }
  &-catalog-import {
    margin-top: 10px;
  }
  &-transaction-report {
    margin-bottom: 20px;
  }
}

.dashboard-modal {
  &.edit-image-modal {
    max-width: 688px;
  }
  &.import-modal {
    max-width: 600px;

    .import-modal__subtitle {
      text-transform: uppercase;
      display: block;
      margin-bottom: 20px;

      i.mdi {
        position: relative;
        top: 1px;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }
  &.config-modal {
    .image-upload-text {
      font-size: 11px;
    }

    .col {
      &.centered {
        align-items: center;
      }
    }
  }
  &-subtitle {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #333333;
    text-transform: uppercase;
  }
}

.text-amount-warn {
  font-weight: 600;
  color: $color-warning-700;
}
.center-prefix-number {
  border-right: solid 1px $color-slate-300;
  border-top: solid 1px $color-slate-300;
  border-bottom: solid 1px $color-slate-300;
  height: $input-height-sm;
  width: $input-height-sm;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    border: 0 !important;
  }
}
.center-prefix-currency {
  position: absolute;
  left: auto;
  right: 0;
  border: none !important;
  z-index: 5;
}
.nav-tabs {
  position: relative;
  border-bottom: 0;

  .nav-item {
    display: inline-block;
    margin-right: 3px;
    font-weight: 500;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #e4e4e4;

    .nav-link {
      border-width: 0 0 1px;
      border-style: solid;
      border-color: #e4e4e4;
      background-color: #f4f4f4;
      padding: 10px 40px;
      margin: 0;
      color: inherit;
      text-decoration: none;

      &:hover,
      &.active {
        border-color: transparent;
        background-color: #fff;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.img-cover {
  object-fit: cover;
}
.input-date-range {
  display: inline-block;
  margin-right: 14px;

  .btn {
    border-color: $color-primary-200;
    background-color: $tenant-date-filter-background-color;
    color: $color-slate-700;

    &.disabled {
      border-color: $color-primary-200;
    }
  }
}

.dashboard-section {
  flex: 1 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  color: $color-gray-600;
  background-color: #f3f3f3;

  .dashboard-section-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
    background-color: $color-gray-50;
    box-shadow: 1px 0 8px rgba(120, 120, 120, 0.6);
    overflow: hidden;
    z-index: 9;

    .dashboard-section-tabs {
      display: flex;
      flex-direction: row;
      position: relative;

      a:hover {
        text-decoration: none;
      }

      .dashboard-section-tabs-underline {
        height: 2px;
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $tenant-primary-color;
        transition: all 0.2s ease-in-out 0s;
      }

      .dashboard-section-tab {
        position: relative;
        display: flex;
        align-items: center;
        height: 54px;
        margin: 0 20px;
        padding: 2px;
        justify-content: center;
        color: $color-gray-600;
        font-size: 15px;
        line-height: 18px;
        text-decoration: none;

        @media (max-width: 576px) {
          margin: 0 12px;
        }

        &.active {
          color: $tenant-primary-color;
          font-weight: 500;
        }

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  .dashboard-section-body {
    margin-top: 54px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;

    .dashboard-section-content {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: 28px 28px 0;
      .box-stretch {
        height: 100%;
        align-items: stretch;
      }
    }
  }

  .vertical-adjustment {
    & > div {
      height: 100%;
    }
    flex: auto;
    @media (max-width: 992px) {
      & > div {
        height: auto;
      }
    }
  }

  .dashboard-card {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background-color: $white;
    color: $color-gray-800;

    &-header {
      //background: #9ff;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px;
      flex-wrap: wrap;

      .flex-content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .form-control {
        height: 48px;
      }
    }

    &-body {
      //background: #cff;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      position: relative;
    }

    &-message {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        font-size: 60px;
        margin-bottom: 12px;
      }

      h3 {
        text-transform: uppercase;
      }
    }

    @media screen and (max-width: 40em) {
      padding: 0;
      &-header {
        & > div {
          width: 100%;
        }
        margin-bottom: 10px !important;
      }
    }
  }
}
.not-information {
  span {
    font-size: 18px;
  }
  span,
  i {
    color: $color-gray-400;
  }
}

.flex-container .positive {
  span,
  i {
    color: $color-success-500;
  }
}

.full-search {
  width: 280px;
  .form-control {
    border-radius: $tenant-input-radius !important;
  }
  @media screen and (max-width: 40em) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.dashboard-table-controls {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  margin-bottom: 16px;

  & > div {
    margin-bottom: 16px;
  }
  .full-search {
    width: 280px;
  }
}
.badge {
  flex: auto;
  margin: 4px;
  height: 24px;
  min-width: 110px;
  line-height: 24px;
  padding: 0 12px;
  vertical-align: middle;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 12px;
  font-size: 11px;

  &.gps-badge {
    padding: 0;
    border-radius: 50%;
    font-size: 14px;
    min-width: 25px;

    &.badge-info {
      color: $white;
      border: 1px solid $color-info-400;
      background-color: $color-info-500;
      line-height: 23px;
    }
  }

  &-block {
    width: 100%;
  }

  &-default {
    color: $white;
    background-color: $color-gray-500;
  }

  &-success {
    color: $color-success-500;
    border: 1px solid $color-success-400;
    background-color: $white;
    line-height: 23px;
  }

  &-info {
    color: $color-info-500;
    border: 1px solid $color-info-400;
    background-color: $white;
    line-height: 23px;
  }

  &-warning {
    color: $color-warning-500;
    background-color: $color-warning-50;
  }
}

.badge-secondary {
  background-color: $color-gray-400;
}

.badge-info {
  border: 0;
  background-color: $color-success-500;
  color: white;
}
.deposit-information {
  display: flex;
  align-items: center;
  &__date {
    color: $color-gray-400;
    margin: 5px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      height: 23px;
    }
    &:hover {
      color: $color-gray-900;
    }
  }
}

.tooltip-inner {
  margin-bottom: 5px !important;
  text-align: left;
  &:after {
    content: "";
    position: absolute;
    top: 83%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #000000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}
.show-taxes {
  display: none !important;
}
.image-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  border-radius: 50%;

  &-left {
    margin-right: 16px;
  }

  &-right {
    margin-left: 16px;
  }

  &-placeholder {
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }
}
.input-group-icon {
  display: flex;
  width: 100%;
  align-items: center;

  div {
    width: 100%;
  }
  input {
    border-radius: $border-radius 0 0 $border-radius !important;
  }

  .center-prefix-number {
    width: 40px;
    margin-top: 3px;
  }
}
.pre-debug {
  visibility: hidden;
  position: absolute;
  font-size: 10px;
  top: 98px;
  left: 4px;
  bottom: 4px;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.3);
}
.dropdown-toggle {
  border-left: 0;
  box-shadow: none !important;
  border-color: $color-slate-300;
}

.absolute-loader {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .loader {
    position: relative;
    margin: 0 auto;
    width: 50px;
    z-index: 1;
  }
  .loader:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: $tenant-primary-color;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

.relative-loader {
  display: flex;
  align-items: center;
  margin: 0 auto;

  .loader {
    position: relative;
    width: 50px;
  }
  .loader:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    margin: auto;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: $tenant-primary-color;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

.loader-row {
  width: 100%;
  height: 80px;
  position: relative;
}

.buy-reader-button {
  margin-right: 16px;

  .btn {
    font-size: 13px;
    font-weight: 500;
    padding-right: 20px;
    vertical-align: middle;
  }

  span {
    margin-bottom: 2px;
  }
}
.reader-widget {
  margin-right: 24px;
  button {
    background-color: #f3c027;
    color: white;
    width: 180px;
    &:hover {
      background-color: #d1a319;
      color: white;
    }
  }
}
.reader-tooltip {
  .tooltip-inner {
    max-width: 260px !important;
  }
}
.buy-reader-loading {
  color: white;
  height: 48px;
  position: relative;
  border: solid 1px #f6c122;
  background-color: #a17a02;
  .loading {
    transition: 0.1s;
    background-color: #f6c122;
    height: 47px;
    position: absolute;
    top: 0;
    left: 0;
  }
  label {
    z-index: 2;
    position: relative;
    padding: 12px 24px;
  }
}
.reader-label-code {
  width: 180px;
  border: solid 1px $color-success-500;
  padding: 5px 0;
  height: 47px;
  margin: 0;
  text-align: center;
  line-height: 18px;
  b {
    font-weight: normal;
    color: $color-success-500;
  }
}
.buy-reader-collapse {
  height: 0;
  overflow: hidden;
  display: inline-block;
  transition: 0.5s;
  width: 100%;
  button {
    padding-left: 51px !important;
  }
}
.buy-reader-collapse-open {
  height: 106px;
}
.dashboard-center-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 16px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      margin: 24px;
      color: $tenant-primary-color;
    }

    span {
      color: #666;
      text-align: center;
      font-size: 16px;
    }
  }

  .modal-footer {
    border-top: none;
    justify-content: space-between;

    .btn {
      height: 40px;
      border-radius: $border-radius;
      padding: 2px 40px;
    }

    .btn-secondary {
      color: $tenant-primary-color;
      border-color: $tenant-primary-color;

      &:hover {
        background-color: $color-primary-50;
      }
    }
  }
}

.dropdown-menu {
  width: auto !important;
}

.input-requirements {
  display: flex;
  margin: 8px -6px;

  .input-requirement {
    flex: 1 0 auto;
    margin: 3px 6px;
    padding: 0 8px;
    height: 28px;
    line-height: 28px;
    border-radius: 100px;
    background-color: #f2f6fa;
    color: #7693ab;
    font-size: 12px;
    text-align: center;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    .input-requirement {
      flex: 0 0 auto;
      margin: 4px 2px;
      height: 24px;
      line-height: 24px;
      font-size: 11px;
    }
  }

  .input-requirement.valid {
    background-color: $teal;
    color: white;
  }
}

.form-footer {
  margin-top: 36px;
}

.dashboard-card-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius;

  &-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.7rem 1.5rem;

    &.color-gray {
      background-color: $color-gray-50;
    }
  }
}

.dropzone-container {
  margin: 0 auto;
  width: 100px;
  position: relative;

  .edit-icon {
    outline: 0;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $color-primary-400;
    border-radius: 50%;
    text-align: center;
    border: 0;
  }
}

.config-dropzone-container {
  width: 100px;
  position: relative;
  margin: 0 20px 0 0;

  .edit-icon {
    outline: 0;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $color-primary-400;
    border-radius: 50%;
    text-align: center;
    border: 0;
  }
}

.dropzone {
  outline: 0;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 1px;
  border-color: $color-primary-100;
  color: $color-primary-100;
  text-align: center;

  img {
    min-width: 100px;
  }

  i.mdi {
    font-size: 64px;
    line-height: 100px;
  }

  &.dropzoneActive {
    color: $green;
    border-color: $green;
    border-style: dashed;

    &.dropzoneHasPreview {
      border-style: dashed;
    }
  }

  &.dropzoneReject {
    color: $red;
    border-color: $red;
    border-style: dashed;

    &.dropzoneHasPreview {
      border-style: dashed;
    }
  }

  &:hover {
    opacity: 0.7;
    border-style: dashed;
    // background-color: $color-gray-100;
  }

  &:active {
    opacity: 1;
    // background-color: $color-gray-200;
  }

  &__buttons {
    margin: 10px 0;
    text-align: center;

    .btn {
      outline: 0;
      padding: 0 5px;
      height: 17px;
      font-size: 11px;
      line-height: 11px;
      color: $tenant-primary-color;
      border: 0;
    }
  }
}

.text-center-box {
  display: flex;
  align-items: center;
  border: solid 1px $color-slate-300;
  border-right: 0;
  padding: 0 5px;
}

.bank-account-modal {
  &-details {
    .col:last-child {
      flex-grow: 0;
    }

    @media (max-width: 992px) {
      flex-direction: column;

      .col {
        flex: 1;
        margin-bottom: 20px;
      }
    }
  }

  form {
    .radio-input {
      margin-top: 10px;
      margin-bottom: 5px;
    }

    input {
      height: 40px;
    }

    & > header {
      margin-bottom: 20px;
    }
  }

  &-form-actions {
    text-align: right;

    button {
      padding: 15px !important;
    }
  }
}

.static-qr-code-modal {
  .box-info {
    border: 1px solid #f0f0f0;
    padding: 10px;
  }

  .bank-emphasise {
    color: $tenant-primary-color;
  }

  .label-warn {
    display: flex;
  }

  .mdi {
    color: $tenant-primary-color;
  }

  .mdi-alert-circle::before {
    margin-top: -6px;
  }

  .mdi-18px.mdi-set,
  .mdi-18px.mdi::before {
    font-size: 22px;
  }
}

@mixin arrow-icon($icon) {
  background-image: none;
  &:before {
    content: $icon;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    color: $tenant-primary-color;
    font-size: 32px;
    top: -6px;
    position: absolute;
  }
}

.slider-tabs {
  height: 50px;
  width: 100%;
  a {
    opacity: 1;
  }
  .carousel-control-prev {
    span {
      position: relative;
      &:before {
        left: -9px;
      }
      @include arrow-icon("\F141");
    }
  }
  .carousel-control-next {
    span {
      position: relative;
      &:before {
        right: -9px;
      }
      @include arrow-icon("\F142");
    }
  }
  .carousel-inner {
    display: flex;
    height: 50px;
    .carousel-item {
      &:before {
        content: "";
        height: 3px;
        width: 30%;
        background-color: $tenant-primary-color;
        position: absolute;
        left: 35%;
        bottom: 0;
      }
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}

.preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $color-gray-50;
  padding: 18px 48px;

  &-title {
    color: $color-gray-400;
    text-align: center;
    font-size: 12px;
    margin-bottom: 14px;
  }

  &-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: solid 1px $color-gray-300;
    background-color: white;
    width: 100%;
    padding: 10px;
    label {
      font-size: 13px;
      font-weight: 500;
      color: $color-gray-500;
      margin-bottom: 0;
    }
    span {
      font-size: 20px;
      font-weight: 500;
      color: $color-gray-900;
      margin-bottom: 13px;
      line-height: 1;
    }
    img {
      width: 100%;
      margin-bottom: 8px;
    }
    button {
      width: 100%;
      height: 40px;
      border: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.currency-header {
  width: 100%;
  overflow: initial !important;
  .row {
    width: 100%;
  }
  .align-right {
    display: flex;
    justify-content: flex-end;
  }
  .form-group {
    margin: -15px 0 0 0;
    width: 80px;
    .Select__control {
      background-color: transparent;
      border: 0;
      caret-color: transparent;
      width: 80px;
    }
  }
}

.payment-button-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  border: 0;
  width: 45px;
  height: 45px;
  padding: 0;
}

.wrapper-map {
  position: relative;
  height: 450px;
}

@mixin social-share($bg) {
  background-color: $bg;
  color: #fff;
  i {
    margin-right: 5px;
  }
}

.facebook-share {
  @include social-share(#3b5998);
}
.twitter-share {
  @include social-share(#0077cc);
}

.reportWidgetModal {
  .Select {
    .Select__control {
      min-height: 40px;
      height: auto;
    }
  }
}

.courier-tracking-menu-item {
  background: $color-primary-50;
  padding: 10px;
  margin: 0 -10px;
  border-radius: $border-radius;
  .title {
    color: $color-gray-700;
    font-weight: bold;
  }
  .caption {
    display: block;
    font-size: 12px;
    padding-left: 26px;
    color: $tenant-primary-color;
    &:after {
      content: " ›";
    }
  }
}

.courier-tracking-modal {
  max-width: 600px;
  .inside-separator {
    margin: 0 -30px;
  }
  .modal-body {
    padding-bottom: 5px;
  }
  p {
    margin: 0;
    color: $color-gray-700;
  }
  .text-bold {
    font-weight: bold;
  }
}

.stepper {
  color: $color-gray-400;
  margin: 15px 10px 30px;
  .step {
    position: relative;
    min-height: 1em;
    & > div:first-child {
      position: static;
      height: 0;
      margin-bottom: -3px;
    }
    & > div:not(:first-child) {
      margin-left: 2.5em;
      padding-left: 1em;
    }
    + .step {
      margin-top: 2em;
    }
    .step-icon {
      background: $color-gray-300;
      position: relative;
      width: 1.75em;
      height: 1.75em;
      line-height: 1.75em;
      border-radius: 7px;
      color: #fff;
      text-align: center;
    }
    .step-icon:after {
      content: " ";
      position: absolute;
      display: block;
      top: 0;
      right: 50%;
      left: 50%;
      height: 100%;
      transform: scale(1, 2);
      transform-origin: 50% -100%;
      border-left: 2px dotted $color-gray-300;
    }
    &:last-child .step-icon:after {
      display: none;
    }
    .title {
      line-height: 1.5em;
    }
    .caption {
      font-size: 0.8em;
    }
  }
  .step-active {
    color: $color-gray-900;
    .step-icon {
      background: $tenant-primary-color;
      position: relative;
      width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
      margin-left: -5px;
    }
    .step-icon:after {
      height: 50%;
      top: 50%;
      margin-left: -1px;
    }
    .title {
      font-weight: bold;
    }
  }
  .step-completed {
    .step-icon {
      background: $color-primary-200;
    }
    .step-icon:after {
      border-left: 2px solid $color-primary-200;
    }
  }
}

.forgot-password-link {
  text-decoration: underline;
  color: $tenant-seconday-color;
}

.login-helper-text {
  margin-top: 3rem;
  color: $color-gray-700;
}