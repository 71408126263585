// Dashboard colors
$dashboard-primary-color: #585858;
$dashboard-secondary-color: #878787;

// Bootstrap v4 base colors
$white: #fff !default;
$black: #000 !default;
$red: #e53935 !default;
$orange: #ffa000 !default;
$yellow: #f6e155 !default;
$green: #5cb85c !default;
$blue: #005ad4 !default;
$teal: #0090d4 !default;
$dark-teal: #1a66a2 !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;

// $color-primary-: palette !default;
$color-primary-50: #fff2ed !default;
$color-primary-100: #c3f1ff !default;
$color-primary-200: #b3d4ff !default;
$color-primary-300: #46abd4 !default;
$color-primary-400: #0090d4 !default;
$color-primary-500: #005dd1 !default;
$color-primary-600: #1526c9 !default;
$color-primary-700: #121676 !default;
$color-primary-800: #0d1647 !default;
$color-primary-900: #05061d !default;

// Secondary palette
$color-info-50: #ffecec !default;
$color-info-100: #ffc1c3 !default;
$color-info-200: #ff8d92 !default;
$color-info-300: #fd5e65 !default;
$color-info-400: #d03b42 !default;
$color-info-500: #93272c !default;
$color-info-600: #7e1b20 !default;
$color-info-700: #6a0e12 !default;
$color-info-800: #51060a !default;
$color-info-900: #3c0205 !default;

// $color-danger:-palette !default;
$color-danger-50: #ffeeef !default;
$color-danger-100: #ffd2d5 !default;
$color-danger-200: #feafb5 !default;
$color-danger-300: #ff858e !default;
$color-danger-400: #ff6671 !default;
$color-danger-500: #e34b56 !default;
$color-danger-600: #e53935 !default;
$color-danger-700: #d32f2f !default;
$color-danger-800: #c62828 !default;
$color-danger-900: #b71c1c !default;

// $color-success:-palette !default;
$color-success-50: #e8fff1 !default;
$color-success-100: #c3ffdb !default;
$color-success-200: #97fec0 !default;
$color-success-300: #67e99b !default;
$color-success-400: #36d576 !default;
$color-success-500: #19b959 !default;
$color-success-600: #129848 !default;
$color-success-700: #097735 !default;
$color-success-800: #065b28 !default;
$color-success-900: #043d1b !default;

// Warning palette
$color-warning-50: #fbe9e7 !default;
$color-warning-300: #ff8a65 !default;
$color-warning-400: #ff7043 !default;
$color-warning-500: #ff5722 !default;
$color-warning-600: #f4511e !default;
$color-warning-700: #e64a19 !default;
$color-warning-800: #d84315 !default;
$color-warning-900: #bf360c !default;

// gray palette
$color-gray-50: #fafafa !default;
$color-gray-100: #f0f0f0 !default;
$color-gray-200: #e4e4e4 !default;
$color-gray-300: #bbb !default;
$color-gray-400: #999 !default;
$color-gray-500: #777 !default;
$color-gray-600: #666 !default;
$color-gray-700: #555 !default;
$color-gray-800: #444 !default;
$color-gray-900: #333 !default;

// Slate palette
$color-slate-50: #f2f6fa !default;
$color-slate-300: #bccad6 !default;
$color-slate-400: #7693ab !default;
$color-slate-500: #5585aa !default;
$color-slate-600: #3b5c76 !default;
$color-slate-700: #2b4355 !default;
$color-slate-800: #223544 !default;
$color-slate-900: #1a2833 !default;
