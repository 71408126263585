$heading-height: 69px;

.dashboard-panel {
  z-index: 999;
  position: fixed;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  background-color: $white;
  overflow-x: hidden;
  transition: 0.4s;

  // TODO: usar materialdesign helpers para esto
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &-bg {
    z-index: 99;
    position: fixed;
    width: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: $tenant-black;

    &.show {
      width: 100%;
      opacity: 0.2;
    }
  }

  &-container {
    .btn i.mdi {
      margin-right: 5px;
    }
    label {
      color: $color-gray-400;
      font-size: 13px;
      margin-bottom: 4px;
    }
    textarea {
      max-height: 190px;
      line-height: 1.3rem;
    }
    input {
      color: $color-gray-900;
      height: $input-height-sm;
    }
    .btn-group {
      button {
        height: $input-height-sm;
      }
    }

    &.expanded {
      .dashboard-panel {
        width: $dashboard-panel-width-lg;
        @media screen and (max-width: 40em) {
          width: $dashboard-panel-width-sm;
        }
      }
    }
  }

  &__heading {
    width: 100%;
    overflow-y: hidden;
    position: relative;
    padding: 0 20px;
    height: $heading-height;
    line-height: $heading-height;
    font-size: 18px;
    font-weight: 500;
    color: $white;
    background: $tenant-primary-color;

    &--close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: $white;
      background: none;
      border: 0;
      outline: none;
    }
  }

  &__content {
    height: calc(100% - #{$heading-height});
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
    min-width: $dashboard-panel-width-sm;
    form {
      height: 100%;
    }
    & > :first-child {
      height: 100%;
      margin: 0;
    }

    .category-folder {
      color: $tenant-primary-color;
    }
  }

  i.mdi {
    font-size: 16px;
    vertical-align: middle;
  }

  .btn-block {
    cursor: pointer;
    margin-bottom: 15px;

    i.mdi {
      margin-right: 5px;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .bold-td-right {
    td {
      &:nth-child(2) {
        font-weight: bold;
        max-width: 212px;
      }
    }
  }

  .no-border-child {
    border: 0 !important;
    margin-bottom: 0 !important;
  }
  .mdi-checkbox-blank-circle-outline {
    font-size: 9px !important;
    margin: 0 5px 0 4px;
  }
  .table {
    margin: 0 0 22px auto;
    color: $color-gray-500;
    border-bottom: solid 1px $color-gray-100;
    border-top: 0;
    &.table-header {
      border: 0;
      margin-bottom: 0;
      tbody {
        padding: 0 !important;
      }
    }
    &.no-border {
      tbody {
        padding: 0 0 22px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        & > tr {
          display: flex;
          justify-content: space-between;
          td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:nth-child(1) {
              width: 65%;
              white-space: unset;
            }
            span {
              margin-right: 10px;
            }
            .badge-primary {
              width: 100%;
              margin: 0;
              background-color: $color-info-500;
            }
            .badge-light {
              border: solid 1px #cacaca;
              color: #747474;
              width: 100%;
              padding: 4px 0 26px;
              border-radius: 87px;
              margin: 25px 0 0;
            }
            .sale-map-link {
              color: $color-gray-500;
            }
          }
          .show-data {
            text-overflow: unset;
            overflow: unset;
          }
        }
      }
      tr {
        width: 100%;
        border-top: 0 !important;
        td {
          padding: 4px 0 !important;
          border-top: 0;
        }

        &:first-of-type {
          td {
            padding: 0.75rem 0 5px;
            //border-top: 1px solid $color-gray-300;
          }
        }

        &:last-of-type {
          td {
            padding: 5px 0 0.75rem;
            border-top: 0;
            border-bottom: 0;
          }
        }
      }
    }

    td {
      padding: 0.75rem 0;
      border-color: $color-gray-300;
      .text-bold {
        color: $color-gray-900;
        font-weight: bold;
        margin-right: 0 !important;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }

    i.mdi {
      margin-right: 5px;
    }

    .panel-total {
      font-weight: 700;
    }
  }

  .sales & {
    .sale-map-link {
      display: inline-block;
      margin-top: 20px;
    }
  }

  .catalog & {
    .catalog__product--image {
      cursor: pointer;
    }
  }
  .form-group {
    margin-bottom: 20px;
  }
}
.panel-flex {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div:nth-child(1) {
    overflow: auto;
    padding: 32px;
    height: 100%;
  }
  & > div:nth-child(2) {
    margin: 32px;
    button {
      margin: 0;
    }
  }
}
.contact-data-th{
  font-size: 16px;
  font-weight: 600;  
  border: 0 !important;
  padding: 0 0 15px 0 !important;
  
}
.client--image,
.catalog__product--image-actions {
  .client--image,
  .catalog__product--image {
    position: relative;
    display: inline-block;
  }

  .catalog__product--edit-image {
    margin-top: 10px;
    border: 0;
    color: $color-gray-400;
  }
}

.field-help {
  margin-bottom: 20px;
  .form-group {
    margin-bottom: 0;
  }
  span {
    font-size: 11px;
  }
}

.text-secondary {
  color: #95a5a6;
}

.block-content {
  padding: 3%;
  background-color: #fafafa;
}

.error-block {
  padding-left: 3%;
  background-color: #ffeeef;
  border-radius: 4px;
  margin-bottom: 4%;
}

.block-spacing {
  margin: 5px 0 5px 0;
}

.config-image-section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 30px 0;
  max-width: 300px;
}
