@import "../variables";

.ecommerceForm-panel {
  padding: 1em;
  margin: 0 0 0 0;
}

.ecommerceForm-information-container {
  padding: 1em;
  color: $color-gray-900;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-mainTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.ecommerceForm-mainSubtitle {
  margin: -10px 0 3em 0;
}

.ecommerceForm-right {
  float: right;
}

.ecommerceForm-title {
  font-size: 18px;
  font-weight: 500;
  color: #cacaca;
}

.ecommerceForm-primary {
  color: $tenant-primary-color;
}

.ecommerceForm-dashboard-card {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &-header {
    //background: #9ff;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
    flex-wrap: wrap;

    .flex-content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .form-control {
      height: 48px;
    }
  }

  &-body {
    //background: #cff;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
  }

  &-message {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      font-size: 60px;
      margin-bottom: 12px;
    }

    h3 {
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 40em) {
    padding: 0;
    &-header {
      & > div {
        width: 100%;
      }
      margin-bottom: 10px !important;
    }
  }
}

.ecommerceForm-panel-flex {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ecommerceForm-section-disabled {
  padding: 32px;
  color: $color-gray-600;
}

.ecommerceForm-section {
  padding: 32px;
  color: $color-gray-900;
}

.ecommerceForm-off {
  opacity: 0.2;
}

.ecommerceForm-pointer {
  cursor: pointer;
}

.ecommerceForm-link-panel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-information-link {
  margin-left: 10px;
  text-decoration: underline !important;
}

.ecommerceForm-urlSection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-linkSection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ecommerceForm-urlInput-container {
  flex-grow: 1;
  margin: 0 10px -15px 0;
}

.ecommerceForm-light-text {
  color: #cacaca;
}

.ecommerceForm-link-button-enable {
  width: 100%;
  max-width: 180px;
  font-size: 15px;
  background-color: #cacaca;
  cursor: pointer;
  font-family: Ubuntu;
  color: $color-gray-900;
  font-size: 15px;
  border-radius: 8px;
  margin: 3px 0 3px 0;
}

.ecommerceForm-icon-button{
  margin: 0 5px 0 0;
}

.ecommerceForm-link-button-disable {
  width: 100%;
  max-width: 180px;
  font-size: 15px;
  background-color: #d3d3d3;
  color: #444444;
  cursor: pointer;
  font-family: Ubuntu;
  font-size: 15px;
  border-radius: 8px;
  margin: 3px 0 3px 0;
}

.ecommerceForm-configuration-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ecommerceForm-mainTitle-container {
  display: flex;
  flex-direction: column;
}

.ecommerceForm-field-label {
  font-weight: bold;
  font-size: 14px;
}

.ecommerceForm-field-description {
  font-size: 12px;
}

.ecommerceForm-field-logo-container {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-dropzoneInfo {
  margin: 0 0 0 5px;
}

.ecommerceForm-saveButton-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-field-help-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: $color-gray-400;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-merchant-field {
  margin: 0 0 0 -15px;
  flex-grow: 1;
}

.ecommerceForm-button-icon {
  border: 0;
  background-color: #fff;
  color: inherit;
  cursor: pointer;
  :active {
    border: none;
  }
  :focus {
    border: none;
  }
}

.ecommerceForm-icon {
  font-size: 18px;
}

.commerceForm-field-dropzone {
  align-self: flex-end;
}

.ecommerceForm-subsidiaries-container {
  display: flex;
  flex-flow: row wrap;
  margin: 10px 0 0 0;
}

.ecommerceForm-subsidiary-field {
  margin: 5px;
}

.ecommerceForm-maxUnitPerProduct-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-maxUnitPerProduct-labels-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-maxUnitPerProduct-error-container {
  align-self: flex-start;
  color: #e34b56;
  font-size: 12px;
}

.ecommerceForm-maxUnitPerProduct-input-error {
  border-color: #e34b56;
}

.ecommerceForm-maxUnitPerProduct-field {
  width: 10% !important;
  margin: 0 5px 0 5px;
}

.ecommerceForm-maxUnitPerProduct-label {
  font-weight: bold;
  margin: 0px;
}

.ecommerceForm-socialNetwork-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.ecommerceForm-min-panel {
  min-height: 155px;
}

.ecommerceForm-configuration-row-container {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
}

.ecommerceForm-subsidiary-label {
  margin: 0 0 0 5px;
}

.ecommerceForm-panel input {
  max-height: 10px;
}

.colWidthFull {
  width: 25%;
}

.ecommerceForm-saveButton {
  width: 100%;
  max-width: 250px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 8px;
  margin: 3px 0 3px 0;
}

.ecommerceForm-alert {
  overflow: unset;
  padding: 20px 32px 0px 32px;
}

.ecommerceForm-main-information {
  font-size: 18px;
  font-weight: 500;
}

.ecommerceForm-img-cover {
  object-position: left;
  width: 250px;
  height: 80px;
}

.ecommerceForm-default-image {
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  width: 250px;
  height: 80px;
  color: $white;
  background-color: $color-gray-400;
  border-radius: 5px;
  border: 1px solid $color-gray-100;
  text-align: center;

  img {
    width: 100%;
  }
}

.ecommerceForm-cropper {
  height: 400px;
  width: 100%;
}

.ecommerceForm-logo-message {
  font-size: 11px;
}

.ecommerceForm-link {
  text-decoration: none;
}

.ecommerceForm-maxUnitPerProduct-input {
  padding: 16px 16px !important;
}

.ecommerceForm-info-modal-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.ecommerceForm-info-modal-text {
  font-size: 18px;
  color: $color-gray-800;
  margin: 0 30px 0 0;
  text-align: justify;
  line-height: 40px;
}

.ecommerceForm-info-modal-image {
  max-width: 350px;
}

.mdi {
  &-whatsapp {
    color: #56D26E;
  }
  &-instagram {
    color: #F452E8;
  }
  &-facebook {
    color: #191C3C;
  }
}
