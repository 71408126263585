@import 'variables';

//Colors
$green: #19B959;
$pink: #E34B56;
$error-bg: #FFEEF0;

.virtual-terminal-wrapper{
  width: 100%;
  max-width: 580px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  background: $white;
  border: 1px solid $color-gray-100;

    @media (max-width: 880px) {
      width: 100%;
      max-width: 100%;
    }

    .header-form {
      width: 100%;
      height: 80px;
      background: $tenant-primary-color;
      color: $white;
      padding: 21px 30px;

      @media (max-width: 767px) {
        padding: 20px;
      }

      span {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        margin-top: 5px;

        @media (max-width: 475px) {
          font-size: 15px;
          margin-top: 8px;
        }

        @media (max-width: 320px) {
          font-size: 12px;
          margin-top: 14px;
        }
      }

      // Btn Clear Data
      button {
        height: 40px;
        width: 137.19px;
        float: right;
        background: $tenant-primary-color;
        border: 1px solid $white;
        color: $white;
        font-size: 15px;
        white-space: initial;
        padding: 0;

        &:focus {
          outline: none;
        }

        &.no-active {
          opacity: 0.4;
          outline: none;

          &:focus {
            outline: none;
          }
        }

        &.active {
          opacity: 1;
        }

        @media (max-width: 375px) {
          width: 95px;
          font-size: 12px;
          padding: 0;
        }
      }
    }

  form {

    &.show-form {
      display: block;
    }

    .virtual-terminal-content {
      position: relative;
      padding: 35px 74px;

      @media (max-width: 767px) {
        padding: 25px 20px;
      }

      .main-title {
        text-transform: uppercase;
        color: $tenant-black !important;
        font-weight: bold;
        font-size: 12px;
        display: inline-block;
        margin-bottom: 15px;
        color: $color-gray-900;
      }

      .transaction-amount {
        width: auto;
        height: auto;
        overflow: hidden;
        position: relative;

        &:after{
          content: "$";
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 10%;
          height: 45px;
          background: #FAFAFA;
          border: 1px solid #CACACA;
          border-right: none;
          padding-top: 10px;
          font-size: 15px;
          border-bottom-left-radius: $border-radius;
          border-top-left-radius: $border-radius;

          @media (max-width: 500px) {
            width: 15%;
          }
        }

        input {
          width: 90%;
          float: right;

          @media (max-width: 500px) {
            width: 85%;
          }
        }
      }



      // Label Subtitle Section
      label {
        font-size: 13px;
        color: $color-gray-400;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 6px;
      }

      input {
        color: $color-gray-900;
        height: 45px;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $color-gray-400;
        }

        &::-moz-placeholder { /* Firefox 19+ */
          color: $color-gray-400;
        }

        &:-ms-input-placeholder { /* IE 10+ */
          color: $color-gray-400;
        }

        &:-moz-placeholder { /* Firefox 18- */
          color: $color-gray-400;
        }
      }

      button {
        width: 100%;
        height: 48px;
        background: $green;
        color: $white;
        border-radius: $border-radius;
        margin-top: 30px;
        font-size: 15px;

        &:hover {
          background: $green;
          color: $white;
        }
      }

      .card-inputs{
        margin: 36px 0 25px 0;

        label {
          overflow: initial;
          margin-bottom: 10px;
          color: #747474;
        }

        .main-title {
          margin-bottom: 14px;
        }

        .card-checkbox {
          margin-top: 9px;

          .mdi-help {
            border-radius: 50%;
            border: 1px solid #747474;
            font-size: 9px;
            margin-left: 10px;
            padding: 1px;
          }

          .form-check {
            display: inline-block;

            .form-check-label {
              i {
                border: 1px solid $tenant-primary-color;
              }

              span {
                margin-left: 6px;
                font-weight: 200;
                font-size: 14px;
              }

              .form-check-input {

                &:checked + i {
                  color: $tenant-primary-color;
                }
              }
            }
          }
        }
      }

      .clients-options {
        width: 111px;
        height: auto;
        overflow: hidden;
        color: $tenant-primary-color;
        position: relative;
        margin-top: 7px;

        span {
          font-size: 14px;
        }

        .mdi-chevron-down, .mdi-chevron-up {
          position: absolute;
          right: 0;
          top: -7px;
          font-size: 24px;
        }
      }

      .clients-options-content {
        width: 100%;
        margin-top: 25px;

        .down-enter {
          height: 0;
        }

        .down-enter.down-enter-active {
          height: 100px;
          -webkit-transition: height .3s ease;
        }

        .down-leave.down-leave-active {
          height: 0;
          -webkit-transition: height .3s ease;
        }

        &.visible {
          display: block;
        }

        &.no-visible{
          display: none;
        }
      }
    }
  }
}

.error-transaction {
  width: 100%;
  height: 78px;
  background: $error-bg;
  color: $pink;
  position: relative;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    padding: 20px 20px;

    @media (max-width: 375px){
      font-size: 15px;
    }

    @media (max-width: 320px){
      font-size: 12px;
      padding: 27px 20px;
    }
  }

  span {
    font-weight: 200;
    font-size: 14px;

    @media (max-width: 375px){
      font-size: 12px;
    }

    @media (max-width: 320px){
      font-size: 10px;
    }
  }

  .close-error {
    float: right;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 10px;
  }

  &.hide {
    display: none;
  }
}

.transaction-approved{
  width: 100%;
  max-width: 580px;
  height: 521px;
  background: $white;
  text-align: center;
  padding: 75px 74px;
  margin: 0 auto;

  @media(max-width: 768px) {
    width: 80%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
  }

  @media(max-width: 600px) {
    width: 100%;
    padding: 30px 60px;
  }

  img {
    height: 105px;
    margin-bottom: 42px;
  }

  h1 {
    font-size: 24px;
    color: $tenant-primary-color;
    font-weight: 500;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    color: $color-info-600;
    margin-top: 18px;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  button {
    height: 48px;
    width: 100%;
    border-radius: $border-radius;
    background: $tenant-primary-color;
    color: $white;
    margin-top: 80px;

    &:hover {
      background: $tenant-primary-color;
      color: $white;
    }
  }
}


.loader-form-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1000;

  .loader-form-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.6);

    .loader {
      width: 90px;
    }
  }
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.toggle-enter {
  animation-name: fade-in;
  animation-duration: 300ms;
}
.toggle-leave {
  animation-name: fade-out;
  animation-duration: 300ms;
}

@keyframes fade-in {
  from {
    opacity: 0.0;
    height: 0;
  }
  to {
    opacity: 1.0;
    height: 100px;
  }
}


@keyframes fade-out {
  from {
    opacity: 1.0;
    height: 100px;
  }
  to {
    opacity: 0.0;
    height: 0;
  }
}
