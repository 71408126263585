@import '../colors.scss';

.staff-panel {
  &-image {
    cursor:pointer;
    width: 70px;
    height: 70px;
    color: $white;
    background-color: $color-gray-400;
    border-radius: 7rem;
    border: 1px solid $color-gray-100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-link {
    padding-left: 0 !important;
  }
  &-subtitle {
    color: $color-slate-700;
    font-size: 20px;
  }
  &-list-privileges {
    list-style-type: none;
    padding-inline-start: 0;
    li {
      margin: 10px 0;
      font-weight: bold;
      font-size: 13px;
      color: $color-gray-700;
    }
  }
}
