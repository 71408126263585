.help-modal {
  &-description {
    color: #2b2b2b;
  }

  &-card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    padding: 16px;
    width: 100%;
    height: 92px;
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(148, 148, 148, 0.25);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    margin: 0 15px 0 0;
    color: $tenant-primary-color;
  }

  &-arrow {
    position: absolute;
    right: 50px;
    color: $tenant-primary-color;
  }

  &-email-mobile {
    display: none;
  }

  @media (max-width: 993px) {
    &-email-mobile {
      display: block;
    } 

    &-email-desktop {
      display: none;
    } 
  }
}

.modal-lg {
  max-width: 670px;
}

.modal-dialog .modal-header {
  padding: 24px 32px 24px 32px;
}