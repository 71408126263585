.actions-card__body {
    gap: 20px;
}

.actions-card__title {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
}

:root {
    --tooltip-shift: -22px;
    --tooltip-move: 32px;
}

$coachmark-tooltip-border-radius: 0px !default;

.actions-card__button {
    height: 40px;
    display: flex;
    align-items: center;
    border-width: 2px;
    border-radius: 31px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 550;
}
// Coach mark
.coach-mark__tooltip {
    fill: $tenant-primary-color;
}

.coach-mark__body {
    display: flex;
    flex-direction: column;
    max-width: 317px;
    padding: 0;
}

.coach-mark__button {
    font-size: 15px;
    border: 2px solid $white;
    align-self: flex-end;
}

.coach-mark__button:hover {
    background: $color-primary-400;
}

// Coach mark (overwrite react-coach-mark styles)
 .hcm-tooltip-base {
    background-color: $color-primary-500;
    border-radius: $coachmark-tooltip-border-radius;
}

.hcm-tooltip-base-bottom::before {
    border-left-width: 22px;
    border-right-width: 22px;
    border-bottom-width: 14px;
    border-bottom-color: $color-primary-500;
}