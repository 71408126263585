.dashboard-header__button {
  height: 36px;
  font-weight: 550;
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.2px;

  &:focus,
  &.focus {
    box-shadow: none;
  }
}
