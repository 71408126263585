@import "../variables.scss";
@import "../bootstrap/variables";
@import "../globals.scss";
@import "../colors.scss";

.credentials {
  font-family: "Roboto", sans-serif !important;

  &-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-main-title {
    padding-top: 40px;
    text-align: center;
    height: 100px;
    width: 260px;
    font-size: 20px;
    font-weight: 700;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }

  &-main-subtitle {
    text-align: center;
    height: 80px;
    width: 300px;
    font-weight: 400;
    font-size: 15px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }

  &-btn {
    font-weight: bold !important;
  }
  &-icon-warning {
    width: 75px;
    height: 75px;
  }
  &-card {
    min-height: 100%;
    &-container {
      width: 100% !important;
      height: 100%;
      padding: 0 !important;
      margin: 0 !important;
    }
    &-row {
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0;
    }
    &-col {
      padding: 0;
      &:nth-child(1) {
        .link-guia-api {
          margin-top: 70px;
        }
      }
      &:nth-child(2) {
        position: relative;
        display: block;
        background-color: $color-gray-50;
        width: 100%;
        height: 100%;
        padding-right: 0 !important;
      }
    }
  }
  &-info-content {
    padding: 140px 60px;
    font-size: 1.071rem;
    &-alert {
      margin: 30px 0 37px 0;
    }
  }
  &-form-content {
    padding: 140px 60px;
    &-title {
      font-size: 1.429rem;
      line-height: 1.429rem;
      color: $tenant-black;
      font-weight: bold;
    }
    &-subtitle {
      font-size: 1.071rem;
      line-height: 1.5;
    }
    &-inner-content {
      margin-top: 42px;
      &-form-label {
        font-size: 1.143rem;
      }
      .credentials-btn {
        margin-top: 50px;
        padding: 1.071rem 4rem;
        transition: all 0.3s ease-in-out;
      }
      .credentials-btn.disabled {
        background-color: #aeaeae;
        border-color: #aeaeae;
      }
    }
  }
  &-list-content {
    padding: 70px 60px;
    height: auto;

    .credentials-card-item {
      margin: 0 -10px;
      padding: 10px 25px;
      border-bottom: 1px solid #cacaca !important;
      border-radius: 2px;
      &-body {
        display: grid;
        flex-direction: row;
        grid-template-columns: 1fr 150px;
        grid-column-gap: 40px;
        align-items: center;
        padding: 0;
        &-title {
          font-size: 1.143rem;
        }
        &-text {
          font-size: 19rem;
        }
        &-btn-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          &-icon-label {
            border: none;
            background: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            font-size: 0.857rem;
            padding: 0;
            .icon-mask {
              width: 28px;
              height: 28px;
              display: flex;
            }
          }
        }
      }
    }
  }
}
.credentials-card-item {
  padding: 40px 25px;
  border: 1px solid #cacaca !important;
  border-radius: 2px;
  &-body {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 150px;
    grid-column-gap: 40px;
    align-items: center;
    padding: 0;
    &-btn-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-title {
    font-size: 1.143rem;
  }
  &-text {
    font-size: 1rem;
  }
  .btn-group-icon-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: none;
    background: transparent;
    padding: 0;
    &:hover {
      background: transparent;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .credentials-card-icon-mask {
    width: 28px;
    height: 28px;
  }
}

.credentials-table {
  display: flex;
  justify-content: space-between !important;
  align-items: flex-end;
  padding: 0 80px;
  padding-top: 0px;
  padding-bottom: 1px;
  &-title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    text-transform: none;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }

  &-text-actions {
    padding-right: 90px;
  }

  &-list {
    display: flex;
    justify-content: center;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
  }

  &-position {
    background-color: #fafafa;
    margin-top: 70px;
    margin-bottom: -70px;
    width: 93%;
  }
}

.credentials-modal {
  padding-top: 110px;
  font-family: "Roboto", sans-serif !important;
  .modal-content {
    min-height: 450px;
  }
  &-body {
    color: $tenant-black !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    flex: unset !important;
    form {
      width: 100%;
    }
    &-steps {
      padding: 94px 124px;
      width: 100%;
    }
    &-api-step {
      padding: 84px 37px 50px 37px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-header {
    position: absolute;
    right: 0;
    left: auto;
    z-index: 30;
  }
  &-title {
    font-size: 1.429rem;
    font-weight: bold;
  }

  &-show-credential {
    padding-left: 40px;
  }
  &-subtitle {
    font-size: 1.143rem;
    border-bottom: 0;
    text-transform: none;
  }
  &-step-button {
    padding: 1.071rem 4rem !important;
    font-size: 1.143rem !important;
    height: auto !important;

    &-btn-login {
      margin-top: 30px;
    }
  }

  &-login-step {
    display: flex;
    flex-direction: column;
    &-error {
      margin-bottom: 16px;
    }

    .login-step-title {
      margin-bottom: 65px;
    }
    .login-step-label {
      font-size: 1.143rem;
      text-align: left;
      align-self: flex-start;
    }
    .login-step-input {
      margin-bottom: -10px !important;
    }

    .login-step-input-error {
      border-color: red;
      color: red;
    }
  }
  &-loader-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .relative-loader {
      margin-bottom: 40px;
    }
  }
  &-warning-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-title {
      font-weight: 700;
      margin-top: 35px;
      margin-bottom: 4px;
    }
    &-subtitle {
      max-width: 75%;
    }
    &-group-btn {
      margin-top: 65px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &-btn-transparent {
      margin-right: 1.2rem;
      color: $tenant-primary-color !important;
      border: none !important;
      font-weight: 700;
      font-size: 1.143rem !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &-error-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-title {
      margin-top: 60px;
    }
    &-subtitle {
      margin-bottom: 80px;
    }
  }
}
.credentials-modal-body-api-step {
  .credentials-modal-title {
    margin-bottom: 28px;
  }

  .credentials-modal-title-position {
    padding-left: 50px;
  }
  .credentials-card-modal {
    margin-left: 40px;
    border: 1px solid #cacaca !important;
    border-radius: 8px;
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .credentials-card-modal-body {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 100px;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    &-content-left {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-secret-code {
      font-weight: bold;
      margin-bottom: 0;
      color: #aaaaaa;
    }

    &-text-copy {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 16px;
      color: $tenant-primary-color;
    }

    &-icon-copy {
      color: $tenant-primary-color;
    }
  }
  .btn {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .credentials-modal-step-button {
    margin: 38px auto 0 auto;
  }
}

.text-position {
  display: flex;
  flex-direction: start;
}

.input-color-error {
  border-color: $red;
}
