@import "../variables";

.ecommerceOrders-badge-COMPLETED {
  background-color: #28a745;
  border-radius: 5px;
}

.ecommerceOrders-badge-SUCCESS {
  background-color: #28a745;
  border-radius: 5px;
}
.ecommerceOrders-badge-PENDING {
  background-color: #6c757d;
  border-radius: 5px;
}

.ecommerceOrders-badge-EXPIRED {
  background-color: #ffc107;
  border-radius: 5px;
}

.ecommerceOrders-badge-FAILED {
  background-color: #dc3545;
  border-radius: 5px;
}

.ecommerceOrders-badge-FAIL_CHECKOUT {
  background-color: #dc3545;
  border-radius: 5px;
}

.ecommerceOrders-emptyPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.ecommerceOrders-status-badge {
  width: 60%;
  height: 30px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ecommerceOrders-modal .modal-header {
  background-color: $tenant-primary-color;
}

.ecommerceOrders-details-modal {
  max-width: 500px;
}

.ecommerceOrders-details-list {
  padding: 0;
}

.ecommerceOrders-details-items-list {
  width: 100%;
}

.ecommerceOrders-modal .modal-header .modal-title {
  color: $color-gray-200;
}

.ecommerceOrders-details-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ecommerceOrders-details-main {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: space-between;
  color: $color-gray-800;
}

.ecommerceOrders-details-status {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
}

.ecommerceOrders-details-total {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.ecommerceOrders-details-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ecommerceOrders-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ecommerceOrders-icon i {
  font-size: 20px;
  margin: 0 5px 0 0;
}

.ecommerceOrders-headerContainer {
  display: flex;
  flex-direction: column;
}

.ecommerceOrders-tableActionsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}


.ecommerceOrders-filtersContainer {
  display: flex;
  flex-flow: wrap row;
  justify-content: flex-start;
  margin: 0 0 10px 0;
}

.ecommerceOrders-pageSizeContainer {
  display: flex;
  flex-flow: wrap row;
  justify-content: flex-start;
}

.ecommerceOrders-inputSearch .input-group{
  height: 48px;
}

.ecommerceOrders-headerButtonsContainer {
  display: flex;
  flex-direction: row;
  height: 48px;
}

.ecommerceOrders-actionButton {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 200px;
  font-size: 15px;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.ecommerceOrders-completeOrdersButton {
  background-color: $tenant-primary-color;
  color: #fff;
}

.ecommerceOrders-details-loader{
  min-height: 30vh;
}

.ecommerceOrders-confirm-main{
  display: flex;
  flex-direction: column;
  padding: 3em;
  justify-content: space-between;
  color: $color-gray-800;
}

.ecommerceOrders-confirm-message {
  font-size: 18px;
}

.statusDropdownContainer {
  min-width: 12em;
  margin: 0 10px 0 10px;
}

.statusDropdownContainer .input-group .Select__control {
  height: 48px;
}