
@import 'variables';

input, button {
  font-family: $font-family-base !important;
  font-weight: 400;
}

.btn {
  font-weight: 400;
}

.input-group {
  .btn {
    padding: 8px 17px;
  }
  .form-control {
    z-index: 0;
    &:focus  {
      border-color:$dashboard-primary-color;
      color:$dashboard-primary-color;
      & + .input-group-btn .btn {
        color: $input-border-focus;
      }
    }
  }
  &-sm {
    .btn {
      padding: 0 12px;
      height: 24px;
      line-height: 16px;
      font-size: 16px;
      color: $text-light;
    }
  }
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend>.input-group-text {
  display: flex;
  align-items: center;
  padding: 8px 16px 9px;
  color: $input-color;
  background-color: $white;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
}

.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.input-group-text {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group-btn .btn-group {
  height: 100%;
}

.input-group.has-feedback {
  .form-control {
    padding-right: 40px;
  }

  .input-group-feedback {
    position: absolute;
    right: 8px;
    top: 1px;
    bottom: 0;
    width: 30px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $color-gray-300;
  }
}

// Chekbox
.form-check {
  .form-check-label {
    padding: 0;
    line-height: 15px;
    cursor: pointer;
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid $text-light;
      border-radius: $border-radius;
      margin-right: 7px;
      background-color: $input-bg;
      color: $input-bg;
      font-weight: 600;
    }
    .form-check-input {
      margin: 0;
      position: absolute;
      opacity: 0;
      &:checked + i {
        color: $text-light;
      }
    }
  }
}
.input-amount::placeholder {
  color: $tenant-black
}

/* Firefox */
.form-control[type="number"] {
  padding: 0 16px;
}

.checkbox{
  label{
    position: relative;
    padding-left: 30px;
  }
  input[type="checkbox"] {
    position: absolute;
    left:0;
    width: 18px;
    height: 18px !important;
    cursor: pointer;
    top:0;
  }
  input[type="checkbox"] + span {
    &:after{
      width: 18px;
      height: 18px;
      cursor: pointer;
      content: '';
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      border: solid 2px $color-gray-500;
      border-radius: 2px;
      opacity: 1;
    }
    &:before{
      width: 18px;
      height: 18px;
      content:'\F12C';
      position:absolute;
      left: 0;
      top:0;
      pointer-events: none;
      border: solid 2px $tenant-primary-color;
      font: normal normal normal 24px/1 "Material Design Icons";
      color: $tenant-primary-color;
      border-radius: 2px;
      font-size: 15px;
      opacity: 0;
    }
  }
  input[type="checkbox"]:checked + span {
    color: $tenant-primary-color;
    &:after{
      opacity: 0;
    }
    &:before{
      opacity: 1;
    }
  }
}

.btn-group-sm {
  .btn {
    font-size: 14px;
    height: $input-height-sm;
  }
}

.input-group {

  .btn-close {
    font-size: 14px;
    margin-left: 6px;

    &:hover {
      cursor: pointer;
    }
  }
}

.form-group {

  .form-control-sm {
    height: $input-height-sm;
    font-size: 14px;
  }

  .form-control-label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1px;
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 15px;
    //margin-bottom: -2px;
    //line-height: 12px;
    //font-size: 12px;
    //color: $tenant-primary-color;
  }

  .form-text {
    margin-left: 1px;
    font-size: 12px;
    color: $text-normal;
  }

  &.disabled {
    .form-control,
    .form-control-label,
    .form-text {
      color: $text-lighter;
    }

    .form-control {
      background-color: $white;
      border: 1px solid $text-lighter;
    }
  }

  &.has-error {
    .form-control,
    .form-control-label,
    .form-text {
      color: $color-danger-500;
    }

    .form-control,
    .Select-control {
      background-color: $color-danger-50;
      border: 1px solid $color-danger-500;
    }
  }

  input.has-error, .has-error{
    background-color: $color-danger-50;
    border: 1px solid $color-danger-500;
  }
}

.input-date-range {

  position: relative;

  .input-group-btn {
    height: 48px;

    .btn {
      border: 0;
      margin: 1px;
      padding: 8px;

      &:focus {
        box-shadow: none;
      }

      i {
        color: $tenant-primary-color;
        font-size: 32px;
        vertical-align: middle;
      }
    }

    .input-date-range-value {
      padding: 8px 24px;
      text-overflow: ellipsis;
      color: $tenant-primary-color;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }

    .input-date-range-value.block {
      flex: 1 auto;
      margin-right: -1px;
    }
  }

  .range-buttons {
    padding: 16px;

    .btn {
      padding: 4px;
      height: 36px;
      border-radius: 0;
    }

    .btn.apply {
      margin-top: 20px;
      background-color: $tenant-primary-color !important;
      border-color: $tenant-primary-color !important;
      color: $white !important;
    }

    .btn.selected {
      background-color: $tenant-primary-color !important;
      border-color: $tenant-primary-color !important;
      color: $white !important;
    }
  }

  .rdr-DateRange {

    color: $color-slate-900 !important;

    .rdr-PredefinedRanges {
      padding: 6px 10px !important;
      width: 180px;

      .rdr-PredefinedRangesItem {
        padding: 8px 12px !important;
        font-size: 13px !important;
        color: $color-slate-800 !important;
        background-color: $color-primary-50 !important;
        border: 1px solid $color-primary-200;
        border-radius: 0 !important;
        &:hover {
          background-color: $color-primary-100 !important;
        }
      }

      .rdr-PredefinedRangesItemActive {
        background-color: $color-primary-100 !important;
      }
    }
    .rdr-Calendar {
      width: 210px !important;
    }

    .rdr-WeekDay,
    .rdr-MonthAndYear {
      color: $color-slate-800 !important;
      font-weight: 400 !important;
    }

    .rdr-Day {
      color: $color-slate-800 !important;
    }
    .rdr-Day.is-inRange {
      background-color: $tenant-primary-color !important;
      color: $white !important;
    }

    .rdr-Day.is-selected {
      background-color: $tenant-primary-color !important;
      color: $white !important;
    }
  }

  &-value.btn {
    min-width: 200px;
    font-size: 14px !important;
  }

  &-editor {
    display: none;
    position: absolute;
    top: 50px;
    left: 1px;
    z-index: 100;
    height: auto;
    padding: 10px 8px;
    overflow: hidden;

    background-color: $white;
    border: 1px solid $input-border-color;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);

    &.show {
      display: flex;
    }
    @media screen and (max-width: 40em){
      flex-direction: column;

    }
  }

  .outer-handler {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 99;
    display: none;
    &.show {
      display: block;
    }
  }
  .btn{
    height: 32px !important;
  }

  @media screen and (max-width: 40em){
    width: 100%;
    margin-right: 0 !important;
    .btn-label-date{
      width: calc(100% - 102px);
    }
  }
}

.input-group {
  &.primary-input-group {
    .form-control {
      border-right: 0;
      & + .input-group-btn .btn { border-left: 0; }
      &:focus  {
        & + .input-group-btn .btn {
          outline: 0;
          color: $white;
          background-color: $input-border-focus;
          border-color: $input-border-focus;
        }
      }
    }
  }
  &.default-input-group {
    .form-control {
      border-right: 0;
      & + .input-group-btn .btn { border-left: 0; }
      &:focus  {
        & + .input-group-btn .btn {
          outline: 0;
          border-color: $input-border-focus;
        }
      }
    }
  }
}

.input-file{
  position: relative;
  i{
    position: absolute;
    right: 5px;
    font-size: 13px !important;
    top: 11px;
  }
  .file-content{
    border: 1px solid $color-slate-300;
    padding: 5px;
    height: 40px;
    display: flex;

    button{
      white-space: nowrap;
      min-width: 89px;
      height: 100%;
      font-size: 11px;
      border: 0;
      background-color: #09d9ce;
    }
    label{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 4px;
      margin-left: 5px;
      width: 67%;
    }
  }
  .document-link{
    position: absolute;
    left: 105px;
    top: 10px;
    color: $color-gray-600;
    font-size: 12px;
    text-decoration: underline;
  }
}

.file-field-dropzone {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 20px;
  width: 100%;
  text-align: center;
  border-style: dashed;
  border-width: 2px;
  -webkit-border-radius: $border-radius-lg;
  -moz-border-radius: $border-radius-lg;
  border-radius: $border-radius-lg;
  color: $tenant-black;
}

button.btn-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor:pointer;
  color: $tenant-primary-color;
  height: auto !important;
  &:hover {
    color: $tenant-primary-color;
  }

  &.btn-secondary {
    color: $color-gray-300;
  }
}

.radio-input{
  padding-left: 40px;
  input{
    display: none;
  }
  input~.radio-btn{
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: $white;
    border: solid $color-slate-400 1px;
    pointer-events: none;
    position: absolute;
    left: 24px;
  }
  input:checked~.radio-btn{
    border: solid $tenant-primary-color 1px;
    &:before{
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      display: inline-block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: $tenant-primary-color;
    }
  }

}

.big-radio {

  height: 28px;
  line-height: 28px;
  margin-bottom: 24px;

  :hover {
    cursor:pointer;
  }

  .radio-out {
    width: 24px;
    height: 24px;
    border: 1px solid $input-border-color;
    border-radius: 50%;
    margin-right: 10px;
    float:left;
  }

  .radio-out.selected {
    border: 1px solid $tenant-primary-color;

    .radio-in {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $tenant-primary-color;
      margin:3px;
      float:left;
    }
  }
}

.color-widget{
  height: 40px;
  &+div{
    border-right: solid 1px #bccad6;
    border-top: solid 1px #bccad6;
    border-bottom: solid 1px #bccad6;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    overflow: hidden;
    position: relative;
    height: 40px;
    width: 40px;
    input{
      height: 100%;
      opacity: 0;
    }
    .widget{
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
    }
  }
}

.double-range-slider {
  margin: 40px 10px;

  &-full-track {
    position: absolute;
    height: 100%;
    background-color: $tenant-primary-color;
  }

  &-empty-track {
    height: 4px;
    width: 100%;
    background-color: $color-gray-300;
  }

  &-thumb {
    outline: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $tenant-primary-color;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  }

  &-values {
    margin-top: 20px;
    font-weight: bold;
    font-size: 20px;
  }

  &-description {
    font-weight: bold;
    font-size: 12px;
  }
}

.filters {
  &-title {
    font-weight: bold;
  }

  &-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 25px;
    color: $tenant-primary-color;
    background-color: $white;
    font-size: 12px !important;
    text-transform: none !important;
    margin: 8px !important;
    min-width: auto !important;

    &-off {
      border: 1px solid $tenant-seconday-color ;
    }

    &-on {
      background-color: $tenant-date-filter-background-color;
      border: 1px solid $tenant-primary-color ;
    }

  }

  &-field {
    &-description {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 8px 0 0;
      gap: 8px;

      &-actions {
        height: 40px;
        padding: 8px 16px;

        &-clean-filters {
          background-color: $white;
          border: solid 1px $tenant-primary-color;
          color: $tenant-primary-color;
        }
        
        &-apply-filters {
          border: solid 1px $tenant-primary-color;
          background-color: $tenant-primary-color;
          color: $white;
        }

        &-disabled {
          border: solid 1px $color-gray-500;
          color: $color-gray-500;
        }
      }
    }
  }

  &-payment-channel-field {
    margin-bottom: 25px;

    .radio-input {
      padding-top: 6px;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      line-height: 24px;

      .radio-label {
        margin-left: 5px;
      }
      
      .radio-btn {    
        width: 22px;
        height: 22px;
        border: solid 2px $tenant-primary-color;
      }    

      input:checked~.radio-btn {
        border: solid $tenant-primary-color 2px;
        &:before {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    &-title {
      margin: 10px;
      font-weight: bold;
    }

    &-badge {
      margin-top: 16px !important;
    }
  }
}
