.bank-account-modal {
  select.form-control:not([size]):not([multiple]) {
    padding:12px 16px;
    height: auto;
  }
  &-disclaimer-edition {
    font-size: 12px;
    font-weight: bolder;
    margin:0;
  }
}
