@import "variables";

.highcharts-tooltip {
  fill: $white;
  fill-opacity: 1;
  z-index: 9998;
}

.chart-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: $text-dark;
}
.justify-chart {
  card-text {
    display: flex;
    align-items: flex-end;
  }
}
.chart-card {
  margin-bottom: 28px;
  flex: auto;

  .card-title {
    font-weight: 400;
    color: $dashboard-primary-color;
    font-size: 16px;
    border-bottom: 2px solid $color-gray-100;
    padding-bottom: 16px;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      margin-left: -2px;
      vertical-align: middle;
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .card-text {
    position: relative;
    font-size: 12px;
    height: 100%;

    .chart-message {
      font-size: 12px;
      margin: 16px 0;
    }
  }

  &-lg {
    .card-title {
      font-size: 32px;
      font-weight: 500;
      i {
        font-size: 28px;
      }
    }
  }
}

.chart-donut {
  margin: 0 16px;

  .chart-donut-graph {
    width: 200px;
    padding: 0;
  }

  .chart-legend {
    padding: 16px;

    display: flex;
    flex-direction: column;

    .chart-legend-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      vertical-align: middle;

      .chart-legend-index {
        width: 22px;
        height: 22px;
        padding: 3px 7px;
        text-align: center;
        vertical-align: middle;
        font-size: 11px;
        font-weight: 700;
        color: $white;
        border-radius: 50%;
        background-color: $tenant-primary-color !important;
      }

      .chart-legend-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 8px;
        flex: 1 1 auto;
      }

      .chart-legend-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 auto;
      }
    }
  }
}

.chart-table {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .chart-table-row {
    padding: 4px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    border-bottom: 1px solid $color-gray-100;
    vertical-align: middle;

    &-primary {
      color: $white;
      font-weight: 500;
      background-color: $tenant-primary-color !important;
      border-color: $tenant-primary-color !important;
      border-radius: $border-radius-xl;
    }
  }
}

.chart-container {
  padding: 0;
  margin-bottom: 16px;

  .chart-content > span {
    margin-left: 10px;
  }

  .chart-header {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 40px;

    .chart-title {
      color: $tenant-primary-color;
      font-size: 16px;
      margin: 0 15px;
      .btn-text {
        color: $tenant-primary-color;
        font-size: 16px;
      }
    }

    .chart-control {
      margin-top: 6px;
      .btn {
        color: $color-gray-500;
        border-radius: 0px;
        height: 38px;
        padding: 4px;
        background-color: transparent;
        border: 0;
        margin-left: 38px;
        font-size: 15px;
        &:focus {
          box-shadow: none;
        }
        &.active {
          border-bottom: solid 3px $tenant-primary-color;
          color: $tenant-primary-color;
        }
      }
    }
  }
}

.chart-tooltip {
  padding: 0;
  margin-top: 1px;
  margin-left: 1px;
  text-align: center;

  .chart-tooltip-title {
    background-color: $color-gray-200;
    color: $color-gray-600;
    padding: 6px 12px;
    margin-bottom: 10px;
  }

  .chart-tooltip-value {
    color: $tenant-primary-color;
    font-size: 15px;
    padding: 0 8px;
    margin-bottom: 10px;

    .small {
      color: $color-gray-500;
      padding: 4px 8px;
      font-size: 13px;
    }

    .secondary {
      color: #63c7e9;
      font-size: 11px;
      padding: 4px 8px;
    }

    .comparison {
      color: $color-gray-600;
      font-size: 13px;
      padding: 6px 8px 12px 8px;

      .icon-major {
        background-color: $green;
      }
      .icon-equal {
        background-color: $yellow;
      }
      .icon-minor {
        background-color: $red;
      }

      .comparison-text {
        vertical-align: middle;
      }

      .comparison-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        line-height: 19px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;

        span {
          color: $white;
          font-size: 15px;
          line-height: inherit;

          &::before {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.chart-legend-card {
  background-color: orange;
  display: flex;
  flex-flow: column nowrap;
}

.chart-legend-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.chart-legend-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between !important;
}

.staff-legend-index {
  width: 22px;
  height: 22px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: top;
  font-size: 11px;
  font-weight: 700;
  color: $white;
  border-radius: 50%;
  margin: 0 3px;
}

.staff-chart-legend {
  .flex-table th {
    background-color: transparent;
  }
  .flex-table tr {
    height: auto;
  }
}

.chart-legend-image {
  width: 50px;
  height: 50px;
  margin: 0 10px 0 0;
  border: none;
}

.chart-donut-staff {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.product-donut-image {
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-top: -2px;
  margin-right: 17px;
  width: 40px;
  height: 40px;
  color: $white;
  background-color: $color-gray-400;
  border-radius: 50%;
  border: 1px solid $color-gray-100;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 43px;

  img {
    margin-top: -2px;
    min-width: auto;
    height: 45px;
  }
}
