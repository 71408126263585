@import "variables.scss";
.input-group {
  .Select {
    margin-right: -1px;
    border-radius: 0;
    &.is-open {
      z-index: 3;
    }
    &__control {
      height: $input-height-sm;
      border-radius: $border-radius;
    }
  }
}

.Select {
  flex: 1 1 auto;

  &__value {
    line-height: $input-height-sm;
    padding-left: 0 !important;
    padding-right: $input-padding-x;

    &__label {
      line-height: $input-height-sm;
      padding-left: 16px;
      color: $input-color !important;
    }

  }

  .Select__indicator-separator{
    width: 0 !important;
  }
  &.Select--single {
    .Select-value {
      line-height: $input-height-sm;
      padding-left: $input-padding-x;
      padding-right: $input-padding-x;

      .Select-value-label {
        color: $input-color !important;
      }
    }
  }

  &.Select--multi {
    .Select-value {
      line-height: $input-height-sm;

      .Select-value-label {
        color: $input-color !important;
      }
    }
  }

  &__control {
    border: $input-btn-border-width solid;
    border-color: $input-border-color !important;
    border-radius: $input-border-radius !important;
    height: $input-height-sm;

    .Select-input {
      line-height: $input-height;
      padding-left: $input-padding-x;
      padding-right: $input-padding-x;

      input {
        color: $dashboard-primary-color !important;
      }
    }

    .Select-arrow-zone {
      padding-top: 2px;
      padding-right: 8px;
      border-color: black;
    }
    &--is-focused{
      box-shadow: none !important;
      border-color: $dashboard-primary-color !important;
    }
  }

  &__option {
    padding: 10px 16px;
    color: $input-color !important;
  }
  &__placeholder {
    line-height: $input-height-sm;
    padding-left: 0px;
    padding-right: $input-padding-x;
    color: $dashboard-primary-color !important;
  }

  &__input {
    input {
      color: $dashboard-primary-color !important;
      height: auto !important;
    }
  }
}