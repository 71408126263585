@import "variables";
@import "colors";

.dashboard-card-header {
  position: relative;

  .form-group {
    margin-bottom: 10px;
  }

  .filters-header {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -10px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    & > div {
      display: flex;
      max-width: 100%;
    }

    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .select-filters {
    display: inline-block;
    width: 120px;

    .btn {
      width: 100%;
      border: solid 1px $color-gray-300 !important;
      border-color: $color-gray-300;
      background-color: transparent;
    }
    @media screen and (max-width: 40em) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .filters-content {
    width: 100%;
    margin-top: 16px;
    margin-bottom: -10px;
  }
  .btn {
    height: 49px !important;
  }
}

@media screen and (max-width: 520px) {
  .dashboard-card-header {
    .filters-header {
      & > div {
        flex-direction: column;
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .actions-header {
    width: 100%;
    .action__create-item {
      margin-left: 0 !important;
      margin-top: 10px;
      width: 100%;
    }
  }
}

.actions-header {
  float: right;

  .btn {
    height: 48px;
    font-size: 15px;
  }

  .action__create-item,
  .select-filters {
    margin-left: 10px;
  }

  .dropdown-menu {
    button {
      font-size: 15px;
    }
  }
}

.auto-sizer-wrapper {
  width: 100%;
}

.table-messages {
  .message-loading {
    margin: 30px 0;
  }

  .message-container {
    border: 1px solid #e4e4e4;
    color: #999;
    padding: 250px 0;

    .el {
      margin-bottom: 20px;
    }

    h3 {
      text-transform: uppercase;
    }
  }
}

.card {
  &-icon {
    display: inline-block;
    margin-right: 20px;
    width: 33px;
    height: 33px;
    text-align: center;
  }
  &-img {
    max-width: 33px;
    max-height: 33px;
  }
}

.status-badge {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;

  &-refund {
    background-color: $color-warning-500;
  }

  &-annulled {
    background-color: $color-gray-700;
  }
}

.status-label {
  display: inline-block;
  padding: 4px 8px;
  width: 116px;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  border-radius: 48px;
  border-style: solid;
  border-width: 1px;
  color: $color-gray-300;
  border-color: $color-gray-300;

  &.active {
    color: $color-info-300;
    border-color: $color-info-300;
  }

  &.refund {
    background-color: $color-warning-500;
  }

  &.inactive,
  &.annulled {
    background-color: $color-gray-700;
  }

  &.refund,
  &.inactive,
  &.annulled {
    color: $white;
  }
}

.flex-table {
  background-color: $white;
  color: $color-gray-600;
  cursor: pointer;

  .ft-icon {
    vertical-align: middle;
    display: inline-block;
    margin-right: 24px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    color: $tenant-primary-color;
    fill: $tenant-primary-color;

    path {
      fill: $tenant-primary-color;
    }
  }

  th {
    background-color: $color-gray-100;
    height: 40px;
    color: $color-gray-400;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
  }
  td.pivoted {
    min-height: 51px;
    padding: 10px 10px 10px 0;
    transition: background-color 0.3s;
    position: relative;
    justify-content: space-between;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #ececec;
      left: 0;
      bottom: 0;
    }

    @media (max-width: 576px) {
      padding-left: 40% !important;
    }
  }

  tr {
    td:last-child,
    th:last-child {
      text-align: right;
      padding-right: 10px;
    }
    td:first-child,
    th:first-child {
      padding-left: 10px;
    }
  }

  tr.highlight-into {
    background-color: initial;
    &:hover {
      background-color: $color-primary-100;
      transition: all 0.1s ease-out;
    }
  }

  .pull-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 40em) {
    tr {
      border: 0 !important;
      border-bottom: 1px solid $color-slate-300 !important;
      font-size: 14px;
      td.pivoted {
        display: flex;
      }
      td:last-child,
      th:last-child {
        &:before {
          height: 0;
        }
      }
    }
  }
}

.table-container {
  display: contents;
}

.panel-expanded {
  .flex-table {
    .ft-row {
      &.selected {
        background-color: $color-primary-100;
      }
    }
  }
}

.flex-table.debug {
  .ft-cell {
    background-color: #dff;
  }
}

.client--name,
.catalog__product--name {
  span {
    display: inline-block;
    vertical-align: middle;
  }

  .client--image,
  .catalog__product--image {
    margin-top: -2px;
    position: relative;
    width: 45px;
    height: 45px;
    line-height: 43px;
    font-size: 16px;

    img {
      margin-top: -2px;
      width: auto;
      height: 45px;
    }
  }
}

.tree-category-folder {
  vertical-align: middle;
  margin-right: 10px;
  color: $tenant-primary-color;
  font-size: 24px;
  line-height: 24px;
}

.tree-separator {
  vertical-align: middle;
  display: inline-block;
  height: 24px;
  margin: 0 20px 0 8px;
  border-right: 2px solid $color-gray-400;
}

.batch-status {
  background-color: $white;
  height: 25px;
  font-size: 12px;
  text-transform:initial;
  &-closed {
    color: $red;
    border: 1px solid $red;
  }
  &-open {
    color: $color-gray-400;
    border: 1px solid $color-gray-400;
  }
}

.table-contact {
  &-info {
    display: flex;
    flex-direction: column;
    &-label {
      font-weight: 500;
      color: #2b2b2b
    }
  }
}
