@import "./variables.scss";

.login-container {
  z-index: 1;
  position: relative;
  padding: 16px;
  margin: auto;
  max-width: 480px;
  .login-logo {
    margin-bottom:34px;
    width: 100%;
    height: fit-content;
    text-align: center;
    img {
      width: auto;
      height: 80px !important;
    }
  }
  .default-input-group {
    .btn {
      color: $color-slate-400;
      background-color: $color-slate-300;
      font-size: 24px;
    }
  }
  .login-welcome {
    margin-bottom: 24px;
    font-size: 18px;
    color: $tenant-black;
  }
  .login-error-message {
    margin-bottom: 10px;
    margin-top: -20px;
  }
  .login-register {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  .form-bottom {
    margin-top: -2px;
  }
}
.login-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 280px;
  text-align: center;
  color: $white;
  box-shadow: 1px 0 8px rgba(50, 50, 50, 0.6);
  background-color: $tenant-primary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 28px;
  z-index: 12000;
  h2 {
    color: $white;
    font-weight: 600;
    font-size: 32px;
  }
  h3 {
    color: $white;
    font-weight: 600;
    font-size: 20px;
  }
  h4 {
    color: $white;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 36px;
  }
  i {
    color: $color-primary-400;
    font-size: 20px;
  }
}
.input-group-prepend {
  .btn {
    border-radius: 0;
    height: 40px;
  }
}
.login-button-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
}
.login-button-container .login-button {
  padding: 10px 40px;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.body-login {
  background-color: $login-background-color;
  color:$tenant-black;
  min-height: 100vh;
  padding: 10vh 0;
}
.custom-login-content {
  color:$tenant-primary-color;
  .login-welcome {
    color:$tenant-primary-color;
    font-weight: bold;
    font-size: 18px;
  }
  .login-welcome-starter {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }
  .login-logo {
    margin:10px auto;
    img {
      width: 210px;
      height: auto !important;
    }
  }
  margin-top:0;
}