@import "../colors.scss";

.login-error__container {
  display: flex;
  min-height: 800px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-error__title {
  font-size: 24px;
  font-weight: 700 !important;
  color: $color-gray-900;
  padding-top: 50px;
}

.login-error__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  padding-top: 10px;
}

.login-error__button {
  margin-top: 1em;
  background-color: #405cf5;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
}

.login-error__button:hover {
  color: lightblue;
  text-decoration: none;
}