@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,600');
@import "./variables";
@import "./bootstrap/bootstrap";
@import "./select";
@import "./globals";
@import "./tenantVariants";
@import "./borders";
@import "./fields";
@import "./dashboard";
@import "./table";
@import "./toggle";
@import "./charts";
@import "./stats";
@import "./login";
@import "./merchant";
@import "./virtualTerminal";
@import "./components/DashboardPanel";
@import "./components/DashboardModal";
@import "./components/DashboardEcommerceForm";
@import "./components/DashboardProductPanel";
@import "./components/DashboardEcommerceOrders";
@import "./components/Paginator";
@import "./material_red";
@import "@mdi/font/scss/materialdesignicons.scss";
@import "./build";
@import "components/DashboardHome";
@import "components/PaymentLinkModal";
@import "components/PaymentLinkForm";
@import "components/PaymentLinkPreview";
@import "components/DashboardHeader";
@import "./components/BankAccountModal";
@import "./newStyles/HelpModal.scss";
@import "components/PaymentButtonSection";
@import "./catalog";
