@import "../variables.scss";
@import "../bootstrap/variables";
@import "../globals.scss";
@import "../colors.scss";
@import "../tenantVariants.scss";

.config-modal {
  &-title {
    color: $tenant-black;
    font-family: Roboto, Arial, sans-serif;
    font-size: 20px;
    line-height: 20x;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 37px;
    padding-bottom: 14px;
  }

  &-subtitle {
    color: $tenant-primary-color;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 37px;
    padding-bottom: 14px;
  }
  &-name {
    color: $tenant-black;
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    line-height: 21.09px;
    font-weight: 500;
    text-transform: capitalize;
  }
  &-text {
    color: #999999;
    font-size: 14px;
  }

  &-merchant {
    color: $tenant-black;
    padding-bottom: 10px;
  }

  &-btn-link {
    font-size: 12px !important;
    color: $tenant-primary-color !important;
    border: none !important;
    background-color: none !important;
    background: none !important;
    border: none;
    cursor: pointer;
    padding: 0 !important;
    padding-top: 5px !important;
    font-weight: 700 !important;
    text-decoration: none !important;
  }

  &-btn-save {
    color: $white !important;
    background-color: $tenant-primary-color !important;
    width: 148px !important;
    height: 46px !important;
    display: block !important;
  }

  &-btn-close {
    color: $tenant-black;
    background-color: $white !important;
    margin-right: 24px !important;
    width: 148px !important;
    height: 46px !important;
  }

  &-edit-icon {
    outline: 0;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $tenant-primary-color !important;
    border-radius: 50%;
    text-align: center;
    border: 0;
  }

  &-card-botton {
    margin-bottom: 40px;
  }

  &-card-item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius;

    &-body {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 1.7rem 1.5rem;
    }
  }

  &-termsAndConditions-Link {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  &-text-uppercase {
    text-transform: capitalize;
  }

  &-no-margin {
    margin-bottom: 0;
  }

  &-image-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 350px;
  }

  &-language-section {
    padding-top: 37px;
    padding-bottom: 14px;
  }

  &-img-cover {
    object-fit: cover;
  }

  &-header-modal {
    padding: 0 30px !important;
    margin-bottom: 0 !important;
    margin-top: 20px !important;
  }

  &-header-modal-cashout {
    padding-top: 40px !important;
  }

  &-header-modal-cashout-text {
    color: #4527a0 !important;
    font-weight: 600;
    font-size: 13px;
  }

  &-Unsubcribe-text {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
  }

  &-text-span {
    text-align: left;
    font-size: 14px;
    margin-right: 220px;
  }
  &-exchange-rate-section {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .form-group, label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
    label {
      margin-bottom: 0;
    }
  }
}

.Unsubcribe-modal {
  font-family: "Roboto", sans-serif !important;
  .modal-content {
    min-height: 270px;
    min-width: 800px !important;
    padding: 0 30px;
  }

  &-content {
    padding: 40px 0px;
    font-size: 10px;
  }

  &-maintitle {
    font-weight: 700;
  }

  &-subtitle {
    padding: 30px 0px 10px 0px;
    color: #b4b4b4;
    font-size: 15px;
  }

  &-btn {
    margin-top: -20px;
    margin-right: -30px;
  }

  &-header {
    position: absolute;
    right: 0;
    left: auto;
    z-index: 30;
  }

  &-btn-send {
    color: white !important;
    background-color: #e34b56 !important;
    border-color: #e34b56;
  }
}
