
// Contextual borders
// ------------------------------

.payment-link-radius {
  border-radius: $tenant-payment-link-radius !important;
}


.buttom-radius {
  border-radius: $tenant-buttom-radius !important;
}

.dropdown-radius {
  border-radius: $tenant-dropdown-radius !important;
}

.chart-table-row-radius {
  border-radius: $tenant-chart-table-row-radius !important;
}

.action-create-item-radius {
  border-radius: $tenant-action-create-item-radius !important;
}

.modal-content {
  border-radius: $tenant-modal-content-radius !important;

  .config-modal-cardItem-radius {
    border-radius: $tenant-config-modal-cardItem-radius !important;
  }
}

.select-radius {
  .Select__control {
    border-radius: $tenant-select-radius !important;
  }
}

.form-control {
  border-radius: $tenant-input-radius !important;
}

.dashboard-panel {
  .btn { 
    border-radius: $tenant-buttom-radius !important;
  }
}