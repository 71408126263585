@import '../variables';
@import '../colors';
@import '../tenantVariants.scss';

#callToActionLogo {
  .st0 {
    fill:$tenant-primary-color;
  }
  .st1 {
    fill: $tenant-terciary-color;
  }
}
.nav-help-phone {
  line-height: 66px;
  color: $tenant-primary-color;
  text-align: right;
  font-size: 15px;
  vertical-align: middle;
  .logo {
    vertical-align: middle;
    margin-right: 10px;
    padding-right: 10px;
    line-height: 35px;
    height: 35px;
    border-right: 1px solid $tenant-black;
    img {
      margin-top: -5px;
      width: 100%;
      max-width: 120px;
      height: 40px;
      pointer-events: none;
    }
  }
  .phone {
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    font-weight: bolder;
    margin-right: 20px;
    span {display: block;}
    a {
      font-size: 16px;
      color: $color-gray-400;
    }
  }
}
