.modal-dialog {
  .close {
    font-size: 34px;
    font-weight: 400;
    color: $color-gray-400;
    margin-bottom: 10px;
  }

  .modal-separator {
    padding: 0 30px;

    hr {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .modal-header {
    padding: 30px;
  }

  .modal-body {
    padding: 0 30px 30px;
    color: $color-gray-400;
    .fields-panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .form-group {
        width: 50%;
        padding: 10px;
      }
    }
    .dashboard-section {
      background-color: white;
    }
  }

  .modal-footer {
    padding: 0 30px 30px;
  }

  .modal-header {
    border: 0;

    .modal-title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      font-weight: bold;
      line-height: 20px;
      font-style: normal;
    }
  }

  .modal-footer {
    border: 0;
  }

  .row {
    margin-bottom: 15px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  .btn i.el {
    margin-right: 5px;
  }

  .subtitle {
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: $color-gray-400;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: 1px solid $color-gray-200;
  }

  .small-text {
    margin: 22px 0;
    font-size: 11px;
    text-align: center;
    color: $color-gray-400;
  }

  .error-text {
    margin: 22px 0;
    font-size: 11px;
    text-align: center;
    color: $color-danger-500;
  }

  .catalog__product--color {
    outline: none;
    vertical-align: top;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 25px;
    margin-right: 24px;
    padding: 0;
    width: 48px;
    height: 48px;
    border: 0;
    background-color: $color-gray-200;
    border-radius: 50%;
    opacity: 0.7;

    &.selected-color,
    &:hover {
      opacity: 1;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  .hidden-modal {
    padding: 30px 30px 0 0;
    outline: 0;
  }
  .dashboard-card-item {
    &-body {
      .row {
        margin-bottom: 0;
      }
    }
  }

  .tabs-header-modal {
    justify-content: center !important;
    box-shadow: none !important;
    background-color: white !important;
    margin-bottom: 38px;
  }
  .tab-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .modal-share-button {
    .modal-footer {
      justify-content: space-between;
      padding: 64px 0 0 0;
      position: relative;
      &:before {
        content: "";
        top: 32px;
        left: 0;
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: $color-gray-100;
      }
      .btn {
        margin-left: 0 !important;
        width: 168px;
        height: 40px;
        line-height: 0;
      }
    }
  }

  .config-user {
    font-size: 18px;
    color: #333333;
  }

  .subsidiary-name {
    color: #333333;
  }

  .btn-primary {
    height: 42.75px;
    display: flex;
    align-items: center;
  }

  .select-language-field {
    display: flex;
    align-items: center;
  }

  .config-modal-footer {
    gap: 24px;
  }

  .config-modal-actions-btn {
    width: 148px;
    height: 46px;
    display: flex;
    justify-content: center;
  }
}
.readers-modal.modal-lg {
  max-width: 800px;
}
