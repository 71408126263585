@import "../variables.scss";
@import "../colors.scss";

.password-form {
  background-color: $login-background-color;
  color:$tenant-black;
  min-height: 100vh;
  padding-top:10vh;
  &-login-container {
    z-index: 1;
    position: relative;
    padding: 16px;
    margin: 0 auto;
    max-width: 480px;
    .form-control {
      border: none !important;
    }
    .primary-input-group.input-with-addon {
      overflow: hidden;
      border:1px solid $color-slate-300;
      border-radius: $tenant-input-radius;
    }
    .input-group-prepend {
      .btn-secondary {
        border-top:none;
        border-right:none;
        border-bottom:none;
      }
    }
  }
  .login-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    img {
      width: auto;
      height: 80px !important;
    }
  }

  &-login-welcome {
    margin-bottom: 24px;
    font-size: 18px;
    text-align: center;
    color: $tenant-black;
  }

  &-error-message {
    margin-bottom: 10px;
    margin-top: -20px;
  }

  &-footer {
    margin-top: 20px;
  }

  &-text-center {
    display: flex;
    align-items: center;
    border-right: 0;
    padding: 0 5px;
    text-align: center;
  }
}
.custom-password-form {
  color:$tenant-primary-color;
  .password-form-login-welcome {
    color:$tenant-primary-color;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 80px;
  }
  .login-logo {
    .img-fluid {
      width: 80px;
    }
  }
}
