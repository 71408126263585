@import '../variables.scss';
@import "../bootstrap/variables";
@import "../globals.scss";
@import "../colors.scss";
@import "../tenantVariants.scss";
@import "../borders.scss";

.payment-link-modal {
  padding: 0 15px;
}

.payment-link-modal__loading-step__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 410px;
}

.payment-link-modal__loading {
  stroke: $tenant-primary-color;
}

.payment-link-modal__copy-step__container {
  background-color: #F8F8F8;
  width: 800px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 14px 80px 40px 80px;
}

.payment-link-modal__copy-step__link-container {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  padding: 40px;

  .input-group {
    justify-content: center;
  }
}

.payment-link-modal__description {
  color: $color-gray-900;
}

.payment-link-modal__steps__title {
  font-size: 24px;
  font-weight: 700 !important;
  color: $color-gray-900;
  padding-top: 20px;
}
.payment-link-modal__steps__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
}


.payment-link-modal__copy-step__description {
  color: $color-gray-900;
}

.payment-link-modal__input {
  height: 40px;
  font-weight: 400;
}

.payment-link-modal__button {
  font-weight: 400;
  width: auto !important;
  font-size: 15px;
  line-height: 15px;

  &:disabled {
    background-color: #AEAEAE;
    border-color: #AEAEAE;
  }
}


.input-payment-link-modal {
  height: 41.68px;
}

.payment-link-modal__copy-step__tooltip {
  .tooltip-inner {
    background-color: $color-success-500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 85px;
    padding: 6px 17px;
  }
  
  .tooltip-inner:after {
    border-top-color: $color-success-500;
  }
}

.payment-link-modal__copy-step__input-group {
  position: relative;
  width: 100%;
  max-width: 400px;
  border:1px solid #d1d1d1;
  border-radius:$tenant-input-radius;
  background: #F8F8FF;
  overflow: hidden;
  #payment-link-modal__copy-step__input {
    background: none;
    color: $tenant-black;
    font-weight: 700;
    max-width: 100%;
    padding-right: 115px;
    border: none !important;
  }
  #copy-link-button {
    position:absolute;
    left:auto;
    right: 0;
    font-weight: 400;
    font-size: 16px;
    height: 100%;
    z-index: 4;
  }
}

.payment-link-modal__loading-error__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 290px;
  padding-bottom: 50px
}

.payment-link-modal__loading-validation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.payment-link-modal__loading-error__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
