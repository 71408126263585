.switch-input {
  display: flex;
  align-items: center;
  .react-toggle {
    margin-left: 10px;
  }
}

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;

}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 34px;
  height: 14px;
  padding: 0;
  margin-left: 5px;
  border-radius: 30px;
  box-shadow: 0 0 2px $color-gray-400;
  border: 1px solid $color-gray-300;
  background-color: $color-gray-300;
  opacity: 0.5;
  transition: all 0.2s ease;
  border: 0;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $color-gray-200;
}

.react-toggle--checked .react-toggle-track {
  background-color: $color-gray-300;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $color-gray-200;
}

.react-toggle-track-check {
  display: none;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  display: none;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: -3px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $color-gray-300;
  box-shadow: 0 0 3px $color-gray-400;
  border-radius: 50%;
  background-color: $white;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 24px;
  background-color: $tenant-primary-color;
  border: 1px solid $tenant-primary-color;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none;
}
