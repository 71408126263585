.buttons-sorting {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttons-actions {
  display: flex;
  justify-content: flex-end;
}

.filters-content .Select__control,
.buttons-sorting .Select__control {
  height: 48px;
}

.filters-content {
  div {
    .form-group {
      .input-group {
        .input-group-prepend {
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: $btn-primary-bg;
        }
      }
    }
  }
}

.filters-content {
  div {
    .form-group {
      .input-group {
        .form-check-input {
          margin: 12px 0;
          transform: scale(1.3);
        }
      }
    }
  }
}

.filters-content {
  div {
    .form-group {
      .input-group {
        p {
          margin-bottom: 0;
          align-self: center;
        }
      }
    }
  }
}

.payment-buttons {
  .filters-content .Select__control {
    border-radius: 9999px !important;
    height: 30px !important;
  }

  .dashboard-card-header .filters-content {
    margin-top: 40px !important;
  }

  .dashboard-section .dashboard-card-header .form-control {
    height: 38px !important;
  }

  .paginationLink {
    width: 35px;
    height: 35px;
  }

  .page-link {
    padding: 0.3rem 0.55rem;
  }

  .statusDropdownContainer .input-group .Select__control,
  .buttons-sorting .Select__control {
    height: 38px;
    border: none;
  }

  .statusDropdownContainer {
    min-width: 9.5em;
  }

  input.form-control {
    height: 38px !important;
  }

  .paginatorContainer {
    padding: 1em 0 0 0;
    margin: 0 !important;
    justify-content: end;
    align-items: center;
  }

  .mdi-sort {
    font-size: 1.7rem !important;
    height: 38px !important;
  }

  .pageSizeText {
    margin-bottom: 0;
  }

  .pageSizeContainer .form-group {
    margin-bottom: 0 !important;
  }

  .buttons-sorting .Select__single-value {
    color: #e4002b;
  }

  .Select__placeholder {
    white-space: nowrap;
  }

  .page-item.active .page-link {
    z-index: unset;
  }
}